import request from '@/utils/request';

// 查询oa集合菜单
export function getAllTab(data) {
	return request({
		url: '/dataCloud/oaMassageTab/getAllTab',
		method: 'get',
		params: data
	});
}
// 查询oa子列表
export function getListByTab(data) {
	return request({
		url: '/dataCloud/oaMsg/byTab',
		method: 'get',
		params: data
	});
}

// 各个系统登录跳转
export function otherLogin(data) {
	return request({
		url: '/dataCloud/oa/otherLogin',
		method: 'get',
		params: data
	});
}
       
// 各个系统登录跳转
export function oaCalendar(data) {
	return request({
		url: '/dataCloud/oaCalendar/message',
		method: 'get',
		params: data
	});
}
   
// 添加备忘录
export function addOaCalendar(data) {
	return request({
		url: '/dataCloud/oaCalendar',
		method: 'post',
		data: data
	});
}

   
// 添加备忘录
export function delOaCalendar(data) {
	return request({
		url: '/dataCloud/oaCalendar',
		method: 'delete',
		params: data
	});
}
// 取消置顶
export function deleteOaSettingTop(data) {
	return request({
		url: '/dataCloud/oaSettingTop',
		method: 'delete',
		params: data
	});
}

// 各个系统登录跳转
export function thirdPartyLogin(data) {
	return request({
		url: '/dataCloud/oa/thirdPartyLogin',
		method: 'get',
		params: data
	});
}
   
// 新增置顶
export function oaSettingTop(data) {
	return request({
		url: '/dataCloud/oaSettingTop/insert',
		method: 'post',
		data: data
	});
}
   
export function getOaMessageListByType(params) {
	return request({
		url: '/dataCloud/oa/getOaMessageListByType',
		method: 'get',
		params: params
	});
}
   
export function getCmsInternalArticleList(data) {
	return request({
		url: '/dataCloud/oa/getCmsInternalArticleList',
		method: 'post',
		data: data
	});
}

export function getOaMessageCount(params) {
	return request({
		url: '/dataCloud/oa/getOaMessageCount',
		method: 'get',
		params: params
	});
}

export function getOaCmsPopularList(params) {
	return request({
		url: '/dataCloud/oa/getOaCmsPopularList',
		method: 'get',
		params: params
	});
}
export function getLinkConfig(params) {
	return request({
		url: '/dataCloud/linkConfig/list',
		method: 'get',
		params: params
	});
}

export function addLinkConfig(data) {
	return request({
		url: '/dataCloud/linkConfig/addLinkConfig',
		method: 'post',
		data: data
	});
}