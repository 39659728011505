<template>
  <el-row style="min-width:1100px" :gutter="10">
    <el-col :span="24" style="padding:0 15px">
      <div class="person_rank">
        <productHeard style="posa" :productOptions="productOptions" @productChange="productChange"></productHeard>
      </div>

    </el-col>
   
  </el-row>
</template>

<script>
import productHeard from "./productHeard.vue";
import {
  findSalesmanInfoByClass
} from "@/api/analysis/data";
import echarts from "echarts";
export default {
  // Component logic here
  props: {
    salesmanId: {
      type: Number, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
  },
  components: {
    productHeard,
  },
  data() {
    return {
      productOptions:[],
      queryData: { salesmanId: null, thisDay: "" },
      trendData: {
        salesmanId: null,
        chartType: 0,
        startTime: "2024-05-01",
        endTime: "2024-05-31",
      },
    };
  },
  mounted() {
    this.queryData.salesmanId = this.trendData.salesmanId = this.salesmanId;
    this.findSalesmanInfoByClass()
  },
  methods: {
     // 产品领域查询
     findSalesmanInfoByClass(){
        findSalesmanInfoByClass(this.trendData).then(res=>{
            console.log('res部门领域产品',res);
            if (res.code==200) {
                this.productOptions=res.data
            }
        })
    },
     // 产品领域选项改变事件
     productChange(e){
        console.log('产品领域选项改变事件',e);
    },
   
  },
};
</script>
<style lang="scss" scoped>
</style>