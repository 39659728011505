var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { "min-width": "1100px" }, attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { staticStyle: { padding: "0 15px" }, attrs: { span: 24 } },
        [
          _c("div", { staticClass: "person_rank" }, [
            _vm._v(" 部门排名"),
            _c("span", [
              _vm._v(" " + _vm._s(_vm.personData.departmentRanking)),
            ]),
            _vm._v(" 集团排名"),
            _c("span", [_vm._v(" " + _vm._s(_vm.personData.groupRanking))]),
          ]),
        ]
      ),
      _c(
        "el-col",
        { attrs: { span: 8 } },
        [
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: { shadow: "hover", "body-style": { padding: "10px" } },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj3.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 月度有效业绩"),
                ]
              ),
              _c(
                "div",
                { staticClass: "card_main" },
                [
                  _c("el-progress", {
                    staticClass: "progress_item",
                    attrs: {
                      width: 150,
                      "stroke-width": 15,
                      type: "circle",
                      percentage: Number(
                        (
                          _vm.personData.effectiveAchievementMonth * 100
                        ).toFixed(2)
                      ),
                    },
                  }),
                  _c("div", { staticClass: "bc_data_right" }, [
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本月目标业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.monthlyTarget
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本月有效业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num hot" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.effectivePriceMonth
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("月度实际业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              Number(
                                _vm.personData.actualPriceSumMonth
                              ).toFixed(2)
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本月退单业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              Number(
                                _vm.personData.refundPriceSumMonth +
                                  _vm.personData.depositRefundPriceSumMonth
                              ).toFixed(2)
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "本周目标业绩",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj3.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 本周有效业绩"),
                ]
              ),
              _c(
                "div",
                { staticClass: "card_main" },
                [
                  _c("el-progress", {
                    staticClass: "progress_item",
                    attrs: {
                      color: "#817fff",
                      width: 150,
                      "stroke-width": 15,
                      type: "circle",
                      percentage: Number(
                        (_vm.personData.effectiveAchievementWeek * 100).toFixed(
                          2
                        )
                      ),
                    },
                  }),
                  _c("div", { staticClass: "bc_data_right" }, [
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本周目标业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.weekTarget
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本周有效业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num hot" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.effectivePriceWeek
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("周度实际业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.actualPriceSumWeek
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本周退单业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              (
                                _vm.personData.refundPriceSumWeek +
                                _vm.personData.depositRefundPriceSumWeek
                              ).toFixed(2)
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-card",
            {
              staticClass: "box-card",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "日目标业绩",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj3.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 日有效业绩"),
                ]
              ),
              _c(
                "div",
                { staticClass: "card_main" },
                [
                  _c("el-progress", {
                    staticClass: "progress_item",
                    attrs: {
                      color: "#ff5f09",
                      width: 150,
                      "stroke-width": 15,
                      type: "circle",
                      percentage: Number(
                        (_vm.personData.effectiveAchievementDay * 100).toFixed(
                          2
                        )
                      ),
                    },
                  }),
                  _c("div", { staticClass: "bc_data_right" }, [
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("日目标业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.dailyTarget
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("日有效业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num hot" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.effectivePriceDay
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("日实际业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              _vm.personData.actualPriceSumDay
                            )
                          )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "bcd_item" }, [
                      _c("div", { staticClass: "bcdi_tle" }, [
                        _vm._v("本日退单业绩"),
                      ]),
                      _c("div", { staticClass: "bcdi_num" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("numberWithCommas")(
                              (
                                _vm.personData.refundPriceSumDay +
                                _vm.personData.depositRefundPriceSumDay
                              ).toFixed(2)
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-col",
        { attrs: { span: 16 } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card2",
                      attrs: {
                        shadow: "hover",
                        "body-style": { padding: "10px" },
                        header: "退单占比",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card_head",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://oss.71go.com/oa0513/yj2.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" 实际业绩数据"),
                        ]
                      ),
                      _c("div", {
                        staticClass: "progress_con",
                        attrs: { id: "refundChart" },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card2",
                      attrs: {
                        shadow: "hover",
                        "body-style": { padding: "10px" },
                        header: "签单产品占比",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card_head",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "https://oss.71go.com/oa0513/yj1.png",
                              alt: "",
                            },
                          }),
                          _vm._v(" 签单产品占比（签单占比排名前五产品）"),
                        ]
                      ),
                      _c("div", { attrs: { id: "prodrate" } }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "main-card",
              attrs: {
                shadow: "hover",
                "body-style": { padding: "10px" },
                header: "签单趋势",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "card_head",
                  attrs: { slot: "header" },
                  slot: "header",
                },
                [
                  _c("img", {
                    attrs: {
                      src: "https://oss.71go.com/oa0513/yj5.png",
                      alt: "",
                    },
                  }),
                  _vm._v(" 签单趋势"),
                ]
              ),
              _c(
                "div",
                { staticClass: "data_change" },
                [
                  _c(
                    "el-radio-group",
                    {
                      staticStyle: { "margin-right": "5px" },
                      attrs: { size: "mini" },
                      on: { input: _vm.changeChartType },
                      model: {
                        value: _vm.trendData.chartType,
                        callback: function ($$v) {
                          _vm.$set(_vm.trendData, "chartType", $$v)
                        },
                        expression: "trendData.chartType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("本周"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("本月"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 2 } }, [
                        _vm._v("本年"),
                      ]),
                    ],
                    1
                  ),
                  _vm.trendData.chartType == 2
                    ? _c("el-date-picker", {
                        staticStyle: { width: "100px" },
                        attrs: {
                          clearable: false,
                          size: "mini",
                          type: "year",
                          placeholder: "选择年",
                        },
                        on: { change: _vm.setYearChanged },
                        model: {
                          value: _vm.setyear,
                          callback: function ($$v) {
                            _vm.setyear = $$v
                          },
                          expression: "setyear",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { attrs: { id: "progress_trend" } }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }