<template>
  <el-row style="min-width: 1100px" :gutter="10">
    <el-col :span="24" style="padding: 0 15px;">
      <div class="df_ac">
        <el-select v-model="classifyNameTwo" placeholder="请选择产品领域" @change="productChange" clearable>
          <el-option
            v-for="(item,index) in productOptions"
            :key="index"
            :label="item.classifyNameTwo"
            :value="item.classifyNameTwo"
          >
          </el-option>
        </el-select>
      </div>
     
    </el-col>
    <el-col :span="5">
      <el-card shadow="hover" :body-style="{ padding: '0px' }" class="box-card">
        <!-- <div class="card_head" slot="header"></div> -->
        <div class="card_main2">
          <div class="bc_data_right2">
            <div class="bcd_item">
              <div class="flex">
                <img src="https://oss.71go.com/oa0513/yj3.png" alt="" />
                <span>周数据</span>
              </div>
              <div class="bcdi_tle">本周完成业绩</div>
              <div class="bcdi_num">
                {{ personData.weekActualPriceSum | numberWithCommas }}
              </div>
            </div>
            <div class="bcd_item">
              <div class="flex">
                <img src="https://oss.71go.com/oa0513/yj3.png" alt="" />
                <span>今日数据</span>
              </div>
              <div class="bcdi_tle">今日完成业绩</div>
              <div class="bcdi_num hot">
                {{ personData.todayActualPriceSum | numberWithCommas }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card
        shadow="hover"
        :body-style="{ padding: '10px' }"
        class="box-card"
        header="本周目标业绩"
      >
        <div class="card_head" slot="header">
          <img src="https://oss.71go.com/oa0513/yj3.png" alt="" /> 月度数据
        </div>
        <div class="card_main">
          <el-progress
            color="#817fff"
            class="progress_item"
            :width="150"
            :stroke-width="15"
            type="circle"
            :percentage="Number(personData.monthPercentage)"
          ></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">月度营销目标</div>
              <div class="bcdi_num">
                {{ personData.monthTaget | numberWithCommas }}
              </div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">月度完成业绩</div>
              <div class="bcdi_num hot">
                {{ personData.monthActualPriceSum | numberWithCommas }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card
        shadow="hover"
        :body-style="{ padding: '10px' }"
        class="box-card"
        header="日目标业绩"
      >
        <div class="card_head" slot="header">
          <img src="https://oss.71go.com/oa0513/yj3.png" alt="" /> 年度数据
        </div>
        <div class="card_main">
          <el-progress
            color="#ff5f09"
            class="progress_item"
            :width="150"
            :stroke-width="15"
            type="circle"
            :percentage="Number(personData.yearPercentage)"
          ></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">年度营销目标</div>
              <div class="bcdi_num">
                {{ personData.yearTaget | numberWithCommas }}
              </div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">年度完成业绩</div>
              <div class="bcdi_num hot">
                {{ personData.yearActualPriceSum | numberWithCommas }}
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="10">
      <el-card
        shadow="hover"
        :body-style="{ padding: '10px' }"
        class="main-card margint-20"
        header="签单趋势"
      >
        <div class="card_head" slot="header">
          <img src="https://oss.71go.com/oa0513/yj5.png" alt="" /> 签单趋势
        </div>
        <div class="data_change">
          <el-radio-group
            @input="changeChartType"
            size="mini"
            v-model="trendData.chartType"
            style="margin-right: 5px"
          >
            <el-radio-button :label="0">本日</el-radio-button>
            <el-radio-button :label="1">本周</el-radio-button>
            <el-radio-button :label="2">本月</el-radio-button>
          </el-radio-group>
          <el-date-picker
            :clearable="false"
            @change="setDayChanged"
            size="mini"
            v-if="trendData.chartType == 0"
            v-model="setDay"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd" 
            format="yyyy-MM-dd"
          >
          </el-date-picker>
          <el-date-picker
            v-if="trendData.chartType == 1"
            :clearable="false"
            @change="setMonthChanged"
            size="mini"
            v-model="setMonth"
            type="month"
            placeholder="选择月"
            value-format="yyyy-MM"
            format="yyyy-MM"
          >
          </el-date-picker>
          <el-date-picker
            :clearable="false"
            @change="setYearChanged"
            size="mini"
            v-if="trendData.chartType == 2"
            v-model="setYear"
            type="year"
            placeholder="选择年"
            value-format="yyyy" 
            format="yyyy"
          >
          </el-date-picker>
        </div>
        <div id="progress_trend"></div>
      </el-card>

      <el-card
        shadow="hover"
        :body-style="{ padding: '10px' }"
        class="main-card  margint-20 "
        header="产品领域业绩占比"
      >
        <div class="card_head" slot="header">
          <img src="https://oss.71go.com/oa0513/yj5.png" alt="" />
          产品领域业绩占比
          <el-tabs v-model="productSortField" @tab-click="productHandleClick">
            <el-tab-pane label="业绩数据" name="0"></el-tab-pane>
            <el-tab-pane label="退款数据" name="1"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="sc_prank">
          <div class="scp_item" v-for="(item,index) in productOptions" :key="index">
            <div class="li_main">
                <div class="r_name"><div class="r_name_icon" :style="{background: item.color}"></div>{{item.classifyNameTwo }}</div>
                <div class="black"> {{item.actualPriceSum | numberWithCommas}}/</div>
                <div class="hui">
                  {{ allActualPriceSum | numberWithCommas}}
                </div>
              </div>
            <div class="r_sche-right">
              <div class="r_sche">  
              <div class="l_inside" :style="{ backgroundColor: item.color, width: (getTagetNum(item.actualPriceSum,allActualPriceSum) > 100? 100 :getTagetNum(item.actualPriceSum,allActualPriceSum)) + '%'}">

              </div>

            </div>
            <div class="r_perf"> {{ getTagetNum(item.actualPriceSum,allActualPriceSum)+ '%'}}</div>
            </div>

          </div>
        
        </div>
      </el-card>
   
    </el-col>
    <el-col :span="9">
      <el-row :gutter="10" v-if="trendData&&trendData.startTime&&trendData.endTime">
        <el-col :span="24">
          <RateTable 
          :tableData="divisionTableData"
            @DivisionEmit="DivisionEmit"
            res="rateTable"
          />
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import RateTable  from "./RateTable.vue";

import {
  findSalesmanInfoByClass,
  findAchievementInfoBydepartmentId,
  findRecentInfo,
  findMonthInfo,
  findYearInfo,
  findWeekRangeList,
  findSaleInfoChart,
  findProductSaleInfo
} from "@/api/analysis/data";
import echarts from "echarts";
export default {
  components: {
    RateTable 
  },
  props: {
    salesmanId: {
      type: Number, // 假设departmentId是字符串类型
      required: true, // 是否必须传递prop
    },
  },
  data() {
    return {
      refundOption: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },

        series: [
          {
            name: "退单比例",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              position: "inner",
              fontSize: 10,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: "部门实际业绩",
            type: "pie",
            radius: ["55%", "75%"],
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}: {c}% ",
              alignTo: "none",
              bleedMargin: 5,
            },

            data: [],
          },
        ],
      },
      setDay: [], // 存储选中的日数
      setMonth: null, // 存储选中的月数
      setYear: null, // 存储选中的年份
      personData: {
        todayActualPriceSum: 0,
        weekActualPriceSum: 0,
        monthActualPriceSum: 0,
        monthTaget: 0,
        monthPercentage: 0,
        yearActualPriceSum: 0,
        yearTaget: 0,
        yearPercentage: 0,
      }, 
      // 个人数据
      queryData: { salesmanId: null, thisDay: "2024-05-08",monthTime:'',year:'' },
      trendData: {
        salesmanId: null,
        chartType: 1,
        sortField:'0',
        startTime: "2024-05-01",
        endTime: "2024-05-31",
      },
      prodrateOption: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} %",
        },
        title: [
        {
            text: '',
            x: '50%',
            y: '43%',
            textAlign: 'center',
            textStyle: {
                fontSize: '12',
                // fontWeight: '500',
                // color: '#98b5d2',
                // textAlign: 'center',
                // textShadowColor: '#000',
                // textShadowBlur: '1',
                // textShadowOffsetX: 2,
                // textShadowOffsetY: 2,
            },
        },
    ], 
        series: [
          {
            name: "产品领域业绩占比",
            type: "pie",
            radius: ["40%", "70%"],
            padAngle: 5,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 3,
              color: "#999",
            },
            label: {
              color: "#333",
              show: true,
              position: "left",
              formatter: "{b}: {c}%", // 显示名称和值，并添加'件'
              itemStyle: {
                borderRadius: 20,
                borderColor: "#fff",
                borderWidth: 3,
                color: "#333",
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
                color: "#333",
              },
            },
            labelLine: {
              show: true,
            },
            data: [],
          },
        ],
      },
      performanceOptions: {
        tooltip: {
          trigger: "item",
        },
        title: [
        {
            text: '',
            x: '50%',
            y: '43%',
            textAlign: 'center',
            textStyle: {
                fontSize: '12',
                // fontWeight: '500',
                // color: '#98b5d2',
                // textAlign: 'center',
                // textShadowColor: '#000',
                // textShadowBlur: '1',
                // textShadowOffsetX: 2,
                // textShadowOffsetY: 2,
            },
        },
    ], 
        series: [
          {
            name: "产品专员业绩排名",
            type: "pie",
            radius: ["40%", "70%"],
            padAngle: 5,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 3,
              color: "#999",
            },
            label: {
              color: "#333",
              show: true,
              position: "left",
              formatter: "{b}: {c}%", // 显示名称和值，并添加'件'
              itemStyle: {
                borderRadius: 20,
                borderColor: "#fff",
                borderWidth: 3,
                color: "#333",
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
                color: "#333",
              },
            },
            labelLine: {
              show: true,
            },
            data: [],
          },
        ],
      },
      trendOption: {
        legend: {},
        tooltip: {},
        dataset: {
          source: [],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#333", // 设置 y 轴文字颜色为 #333
          },
          axisLine: {
            lineStyle: {
              color: "#eee", // 设置横轴颜色为 #666
            },
          },
          axisTick: {
            lineStyle: {
              color: "#eee", // 设置刻度线颜色为 #666
            },
          },
          axisPointer: {
            lineStyle: {
              color: "#eee", // 设置指示器线的颜色为 #eee
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", // 设置分隔线为虚线
              opacity: 0.6, // 设置透明度
            },
          },
          axisLabel: {
            color: "#333", // 设置 y 轴文字颜色为 #333
          },
          axisLine: {
            lineStyle: {
              color: "#eee", // 设置横轴颜色为 #666
            },
          },
          axisTick: {
            lineStyle: {
              color: "#eee", // 设置刻度线颜色为 #666
            },
          },
          axisPointer: {
            lineStyle: {
              color: "#eee", // 设置指示器线的颜色为 #eee
            },
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            itemStyle: {
              color: "#4184f2",
            },
            barWidth: "12%", // Decrease the width of the bars
            barCategoryGap: "30%", // Increase the gap between bar categories
            barGap: "50%", // Increase the gap between bars within the same category
            label: {
              show: true,
              position: [-50, -10], // Show label on right of the bars
            },
          }, // Custom color for the first bar series
          {
            type: "bar",
            itemStyle: { color: "#587FFC" },
            barWidth: "12%", // Decrease the width of the bars
            barCategoryGap: "10%", // Increase the gap between bar categories
            barGap: "20%", // Increase the gap between bars within the same category
            label: {
              show: true,
              position: [10, -20], // Show label on top of the bars
            },
          }, // Custom color for the second bar series
        ],
      },
      classifyNameTwo:'',//产品领域值
      productOptions:[],//产品领域数组
      performanceTableData:[],//产品领域数组
      divisionTableData:[],//产品领域数组
      allActualPriceSum:0, //产品领域总价格
      colorList:[
        "#58ADFC",
        "#2CD3D3",
        "#CEAAFD",
        "#FF815B",
        "#67C3FF",
        "#7596FF",
        "#E4C677",
        "#4CDDA9",
        "#B5CCFF",
        "#B8CDF5",
        "#52E2C0",
        "#FF727C",
        "#CBB385"
      ],
      currentDate:'',
      currentYear:'',
      currentMonth:'',
      currentDay:'',
      productSortField:'0', //产品领域业绩占比 tab栏  0 业绩数据  1退款数据
      performanceSortField:'0', //  产品专员业绩排名 tab栏  0 业绩额  1完成率
      divisionSortField:'0', //产品数据明细 tab栏  0 成交总量  1成交总额
      divisionProductName:'' //产品数据明细 搜索产品名称
    };
  },
  async mounted() {
    // this.queryData.salesmanId = this.trendData.salesmanId = this.salesmanId;
    await this.init()
    // this.getDetail();
  
    // 产品领域查询
    await this.findSalesmanInfoByClass()
    // 本周数据
    await this.findRecentInfo();
    // 本月数据
    await this.findMonthInfo();
    // 本年数据
    await this.findYearInfo();
     // 查询当月有多少周
    await this.calculateWeeks();
    //  签单趋势
    await this.gettrendData();
    // 产品数据明细表格
    await this.findProductSaleInfo()
  },
  methods: {

    init(){
      console.log('初始化salesmanIdid：',this.salesmanId);
      this.currentDate = new Date(); // 获取当前日期
      this.currentYear = this.currentDate.getFullYear();
      this.currentMonth = this.currentDate.getMonth() + 1;
      this.currentDay= this.currentDate.getDay();
      this.trendData.monthTime = `${this.currentYear}-${this.currentMonth.toString().padStart(2, "0")}`;
      this.trendData.year = this.currentYear;
      this.setMonth = this.trendData.monthTime;
      this.setYear = this.trendData.year;
      this.productSortField='0'//产品领域业绩占比 tab栏  0 业绩数据  1退款数据
      this.performanceSortField='0' //  产品专员业绩排名 tab栏  0 业绩额  1完成率
      this.divisionSortField='0' //产品数据明细 tab栏  0 成交总量  1成交总额
      this.divisionProductName=''
      this.getSaleInfo()
    },
    // 产品数据明细表格
    async findProductSaleInfo() {
      let params = {
        salesmanId: this.salesmanId,
        classifyNameTwo: this.classifyNameTwo,
        productName: this.divisionProductName,
        sortField: this.divisionSortField,
        startTime:this.trendData.startTime,
        endTime:this.trendData.endTime,
      };
      const { data } = await findProductSaleInfo(params);
      this.divisionTableData = data;
    },
  
    // 产品领域查询
    async findSalesmanInfoByClass(){
      let params = {
        salesmanId: this.salesmanId,
        startTime:this.trendData.startTime,
        endTime:this.trendData.endTime,
        classifyNameTwo: this.classifyNameTwo,
      };
      const { data } = await findSalesmanInfoByClass(params);
      let dataList=data.achievementList
      this.allActualPriceSum=data.achievementSum
      this.productOptions= dataList.map((item, index) => {
          // 使用模运算符确保颜色循环使用
          const colorIndex = index % this.colorList.length;
          return {
              ...item, // 保留原始对象的所有属性
              color: this.colorList[colorIndex] // 添加 color 字段
          };
      });
      let arr = dataList.map((item, index) => {
        const colorIndex = index % this.colorList.length;
        let standardValue=this.getTagetNum(item.actualPriceSum,this.allActualPriceSum)
        if (standardValue>='10') {
          console.log('standardValue',standardValue);
          return {
          value: this.getTagetNum(item.actualPriceSum,this.allActualPriceSum),
          itemStyle: { color: this.colorList[colorIndex] },
          name: item.classifyNameTwo,
        };
        }
       
      });
      // this.classifyNameTwo=this.productOptions[0].classifyNameTwo
      this.prodrateOption.series[0].data = arr;
      this.prodrateOption.title[0].text = this.allActualPriceSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.initChart();
      console.log('this.productOptions',this.productOptions);
    },
    // 完成率计算
    getTagetNum(num, tagetNum) {
      let taget = 0;
      if (tagetNum !== null && tagetNum > 0) {
        taget = (num / tagetNum) * 100;
      }
      return taget.toFixed(2);
    },
    async findRecentInfo() {
      let params = {
        salesmanId: this.salesmanId,
        classifyNameTwo: this.classifyNameTwo,
        startTime:this.trendData.startTime,
        endTime:this.trendData.endTime,
      };
      const { data } = await findRecentInfo(params);
      this.personData.todayActualPriceSum = data.today.actualPriceSum;
      this.personData.weekActualPriceSum = data.week.actualPriceSum;
    },
    async findMonthInfo() {
      let params = {
        salesmanId: this.salesmanId,
        classifyNameTwo: this.classifyNameTwo,
        monthTime: this.trendData.monthTime,
      };
      this.queryData.monthTime=params.monthTime
      const { data } = await findMonthInfo(params);
      this.personData.monthActualPriceSum = data.actualPriceSum;
      this.personData.monthTaget = data.target;
      this.personData.monthPercentage = this.getTagetNum(
        data.actualPriceSum,
        data.target
      );
    },
    async findYearInfo() {
      let params = {
        salesmanId: this.salesmanId,
        classifyNameTwo: this.classifyNameTwo,
        year: this.trendData.year,
      };
      const { data } = await findYearInfo(params);
      this.personData.yearActualPriceSum = data.actualPriceSum;
      this.personData.yearTaget = data.target;
      this.personData.yearPercentage = this.getTagetNum(
        data.actualPriceSum,
        data.target
      );
    },
    async getSaleInfo() {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
        firstDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-01`;
      this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
        lastDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
    },
    initrefundChart() {
      var refundDom = document.getElementById("refundChart");
      var refund = echarts.init(refundDom);
      refund.setOption(this.refundOption,{ notMerge: true });
    },

    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      // 补零操作
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return `${year}-${month}-${day}`;
    },
    initChart() {
      var chartDom = document.getElementById("prodrate");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.prodrateOption,{ notMerge: true });
    },
    initProdrateOptionsChart() {
      var chartDom = document.getElementById("performance");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.performanceOptions,{ notMerge: true });
    },
    initTrendChart() {
      var chartDom2 = document.getElementById("progress_trend");
      var myChart2 = echarts.init(chartDom2);
      myChart2.setOption(this.trendOption,{ notMerge: true });
    },
    async getDetail() {
      this.queryData.thisDay = this.getCurrentDate();
      let res = await findAchievementInfoBydepartmentId(this.queryData);
      if (res.code == 200) {
        this.personData = res.data;
        this.refundOption.series[0].data = [
          {
            value: res.data.depositRefundPriceSumMonth,
            name: "预存款",
            itemStyle: { color: "#4184f2" },
          },
          {
            value: res.data.refundPriceSumMonth,
            name: "退款",
            itemStyle: { color: "#17a3ff" },
          },
        ];
        this.refundOption.series[1].data = [
          {
            value: Number(
              (this.personData.monthlyAchievement * 100).toFixed(2)
            ),
            name: "完成",
            itemStyle: { color: "#3e7aff" },
          },
          {
            value: Number(
              (100 - this.personData.monthlyAchievement * 100).toFixed(2)
            ),
            name: "未完成",
            itemStyle: { color: "#adceff" },
            label: {
              show: false, // 设置为true以显示label
            },
          },
        ];
        this.initrefundChart();
      }
    },
    async gettrendData() {
      this.trendData.salesmanId= this.salesmanId
      let res = await findSaleInfoChart(this.trendData);

      if (res.code == 200) {
        let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
        switch (this.trendData.chartType) {
          case 0:
            let source = res.data.map((item, indexCheck) => {
              return [week[indexCheck], item.targetSum, item.actualPriceSum];
            });
            // source.unshift(["target", "业绩目标", "有效业绩"]);

            this.trendOption.dataset.source = source;
            console.log('source',source);
            this.initTrendChart();

            break;
          case 1:
            let weekNum = ["第一周", "第二周", "第三周", "第四周", "第五周"];
            let source2 = res.data.map((item, indexCheck) => {
              return [
                weekNum[item.weekNumber - 1],
                item.targetSum,
                item.actualPriceSum,
              ];
            });
            // source2.unshift(["target", "业绩目标", "有效业绩"]);
            console.log('source2',source2);
            this.trendOption.dataset.source = source2;
            this.initTrendChart();

            break;
          case 2:
            const regex = /-(\d{2})$/; // 匹配后缀的两个数字
            let source3 = res.data.map((item, indexCheck) => {
              console.log('item',item);
              let month= item.monthTime.match(regex)
              let months=0
              if (month) {
                  const monthWithLeadingZero = month[1]; // 捕获组，包含前导零
                  months = parseInt(monthWithLeadingZero, 10)+'月'; // 转换为数字，自动去掉前导零
                  console.log('months',months); // 输出: 1
              } else {
                  console.log("没有找到匹配的月份");
              }
              return [ months, item.targetSum, item.actualPriceSum  ];
            });
            // source3.unshift(["target", "业绩目标", "有效业绩"]);
            console.log('source3',source3);
            this.trendOption.dataset.source = source3;
            this.initTrendChart();

            break;

          default:
            break;
        }
      }
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 计算上一周的时间
    getWeekTime(currentDate,currentDay){
         // 计算本周一的日期
         let monday = new Date(currentDate);
        monday.setDate(monday.getDate() - currentDay + 1); // 本周一的日期

        // 计算本周日的日期
        let sunday = new Date(currentDate);
        sunday.setDate(sunday.getDate() - currentDay + 7); // 本周日的日期

        this.trendData.startTime = this.formatDate(monday); // 将本周一的日期赋给 startTime
        this.trendData.endTime = this.formatDate(sunday); // 将本周日的日期赋给 endTime
        this.setDay=[this.trendData.startTime,this.trendData.endTime]
    },
     // 初始化清空所有筛选条件
     clearQuery(){
      this.trendData.monthTime =null;
      this.trendData.startTime = null;
      this.trendData.endTime = null;
      this.trendData.year = null;
      this.trendData.sortField = '0';
      this.setDay=[]
      this.setMonth=null
      this.setYear=null
    },
    changeChartType(value) {
      this.clearQuery()
      if (value == 0) {
        this.getWeekTime(this.currentDate,this.currentDay)
        // this.setDay = [this.trendData.startTime,this.trendData.endTime];
      } else if (value == 1) {
        // 本周
        console.log('this.currentMonth',this.currentMonth);
        this.trendData.monthTime = `${this.currentYear}-${this.currentMonth.toString().padStart(2, "0")}`;
        this.getSaleInfo()
        this.setMonth = this.trendData.monthTime;
         // 本月
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );
        this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-01`;
        this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
        // 本月数据
        this.findMonthInfo();
      } else if (value == 2) {
        // 本月
        console.log('this.currentYear',this.currentYear);
        this.trendData.year = `${this.currentYear}`;
        this.setYear =  this.trendData.year;
        this.trendData.startTime = `${this.currentYear}-01-01`;
        this.trendData.endTime = `${this.currentYear}-12-31`;
        // 本年数据
        this.findYearInfo();
      }
      this.$forceUpdate()
      //  签单趋势
      this.gettrendData();
      this.findSalesmanInfoByClass()
      // 产品数据明细表格
      this.findProductSaleInfo()
    },
    // 年份选项改变
    setYearChanged(value) {
      this.trendData.year = value;
      this.setYear =  this.trendData.year;
      const selectedYear = new Date(value).getFullYear();
      const firstDayOfYear = `${selectedYear}-01-01`;
      const lastDayOfYear = `${selectedYear}-12-31`;
      this.trendData.startTime = firstDayOfYear;
      this.trendData.endTime = lastDayOfYear;
      this.gettrendData();
      this.findYearInfo()
      this.findSalesmanInfoByClass()
      // 产品数据明细表格
      this.findProductSaleInfo()
    },
    // 周数选项改变
    setMonthChanged(va) {
      this.trendData.monthTime = va;
      this.trendData.monthTime = va;
      const [year, month] = this.trendData.monthTime.split('-').map(Number); // 转换为数字
      const firstDayOfMonth = new Date(year, month - 1, 1); // month - 1 因为月份从 0 开始

      // 创建指定月份的最后一天
      const lastDayOfMonth = new Date(year, month, 0); // 使用 0 获取上一个月的最后一天

      // 格式化为 YYYY-MM-DD
      this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
      ).toString().padStart(2, '0')}-01`;
      
      this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
      ).toString().padStart(2, '0')}-${lastDayOfMonth.getDate()}`;
      this.gettrendData();
      this.findMonthInfo()
      this.findSalesmanInfoByClass()
      // 产品数据明细表格
      this.findProductSaleInfo()
    },
    //天数选项改变
    setDayChanged(va) {
      this.trendData.startTime = va[0];
      this.trendData.endTime = va[1];
      this.gettrendData();
      this.findSalesmanInfoByClass()
      // 产品数据明细表格
      this.findProductSaleInfo()
    },
    // 产品领域选项改变事件
    productChange(e){
       console.log('产品领域选项改变事件',e);
       this.clearQuery()
       this.init()
       this.classifyNameTwo=e
       this.trendData.chartType=1
       this.findSalesmanInfoByClass()
       this.findRecentInfo() 
       this.findMonthInfo() 
       this.findYearInfo()
       //  签单趋势
       this.gettrendData();
      // 产品数据明细表格
      this.findProductSaleInfo()
    },
    // 本月有多少周
    async calculateWeeks() {
      let res = await findWeekRangeList({
        monthTime: this.queryData.monthTime,
      });
      this.weeks = res.data;
    },
    // 产品领域业绩占比切换tab
    productHandleClick(){
      this.findSalesmanInfoByClass()
    },
    // 产品专员业绩占比切换tab
    performanceHandleClick() {
      console.log('产品专员业绩占比切换tab',this.performanceSortField);
      this.findSalesmanInfoByDepartment();
    },
    // 产品数据明细切换tab
    DivisionEmit(data){
      console.log('产品数据明细切换tab',data);
      this.divisionSortField=data.sortField
      this.divisionProductName=data.productName
      this.findProductSaleInfo()
    }
  },
};
</script>
<style lang="scss" scoped>

.df_ac {
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  position: fixed;
  top: 10px;
  left: 207px;
  z-index: 999;
  .person_rank {
    display: flex;
    align-items: center;
    width: 309px;
    height: 50px;
    background: url("https://oss.71go.com/oa0513/yj4.png");
    background-size: contain;
    background-repeat: no-repeat;
    justify-content: space-around;
    color: #333333;
    padding-left: 30px;
    transform: scale(0.85);
    span {
      display: inline-block;
      color: #b47614;
      transform: translateY(1px);
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.sc_prank {
  height: 168px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 创建两列 */
  gap: 10px; /* 列之间的间距 */
  padding: 10px; /* 容器内边距 */
  // padding: 0px 10px 0px;
  overflow-y: scroll;
    overflow-x: hidden;
  .scp_item {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 358px;
    position: relative;
    .r_perf {
      position: absolute;
      bottom: -2px;
      right: 0%;
      font-size: 15px;
      font-family: OPPOSans;
      font-weight: 500;
      color: #333333;
      /* margin-left: 4px; */
      min-width: 100px;
    }
   
    .li_main {
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: baseline;
        font-size: 14px;
        color: #fff;
        margin-bottom: 12px;
        .r_name {
          font-size: 16px; /* 0.42rem converted to px and rounded to nearest integer */
          width: 72px;
          margin-right: 50px;
          display: flex;
          align-items: center;
          white-space: nowrap;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 14px;
          color: #333333;
          .r_name_icon{
            width: 6px;
            height: 6px;
            border-radius: 12px;
            margin-right: 6px;
          }
        }
        .black{
          font-size: 12px;
          color: #333333;
        }
        .hui{
          font-size: 12px;
          color: #999999;
        }
      }
    .r_sche-right{
      .r_sche {
      width: 70%;
      height: 10px;
      background: #e6ebf5;
      text-align: right;
      position: relative;
      box-sizing: border-box;
      border-radius: 20px;
      overflow: hidden;

     
      .l_inside {
        box-sizing: border-box;
        height: 10px; /* Same as parent element's height */
        float: left;
        transition: width 0.5s ease-in-out;
        width: 0%; /* Initial width */
        text-align: left;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        color: #fff;
        font-size: 12px; /* 0.35rem converted to px and rounded to nearest integer */
        line-height: 10px;
        border-radius: 20px;
      }
    }
    }
   
  }
}
.sc_prank::-webkit-scrollbar{
  width: 0;
  height: 0;
  color: transparent;
  display:none;
} 

.box-card {
  height: 250px;
  margin: 20px 0;
  .card_main {
    display: flex;
    .bc_data_right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      border-left: 1px solid #e6ebf5;
      padding: 10px;
      min-width: 263px;
      .bcd_item {
        width: 95%;
        margin: 8px 6px;
        min-width: 100px;
        .bcdi_tle {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #666666;
        }
        .hot {
          color: #ff3b3b !important;
        }
        .bcdi_num {
          font-family: DIN, DIN;
          font-weight: 500;
          font-size: 22px;
          color: #333333;
          margin-top: 3px;
        }
      }
    }
  }
}
.box-card2 {
  height: 250px;
  margin: 20px 0px;
  margin-right: 10px;
}
.h340{
  height: 340px;
}
.h260{
  height: 260px;
}
.main-card {
  // height: 520px;
  position: relative;
}
.h530{
  height: 530px;
}
.progress_item {
  margin: 10px;
}
.progress_con {
  display: flex;
  justify-content: center;
}
#progress_trend {
  height: 445px;
  width: 100%;
}
#prodrate {
  height: 185px;
  width: 100%;
}
#performance {
  height: 185px;
  width: 100%;
}
#refundChart {
  height: 185px;
  width: 100%;
}
.data_change {
  position: absolute;
  right: 91px;
  top: 42px;
  z-index: 99999;
  display: flex;
  align-items: center;
}
.person_rank {
  transform: scale(0.85);
  display: flex;
  align-items: center;
  width: 309px;
  height: 50px;
  background: url("https://oss.71go.com/oa0513/yj4.png");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: space-around;
  color: #333333;
  padding-left: 30px;
  position: fixed;
  top: 0;
  left: 207px;
  z-index: 999;
  span {
    display: inline-block;
    color: #b47614;
    transform: translateY(1px);
    font-weight: 600;
    font-size: 18px;
  }
}
.card_head {
  color: #333;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.margint-20 {
  margin-top: 20px;
}
.card_main2 {
  display: flex;
  .bc_data_right2 {
    display: flex;
    padding: 10px;
    width: 100%;
    margin-top: 50px;
    .flex {
      display: flex;
      align-items: center;
      img {
        margin-right: 10px;
      }
    }
    .bcd_item {
      width: 45%;
      margin: 8px 6px;
      min-width: 100px;
      .bcdi_tle {
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 14px;
        color: #666666;
        margin-top: 30px;
      }
      .hot {
        color: #ff3b3b !important;
      }
      .bcdi_num {
        font-family: DIN, DIN;
        font-weight: 500;
        font-size: 22px;
        color: #333333;
        margin-top: 3px;
      }
    }
  }
}
::v-deep .el-tabs .el-tabs__header{
  margin: 0 0 0 10px ;
}
</style>
<style>
.el-card__header {
  border-bottom: none;
}
</style>
