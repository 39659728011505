<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="字典名称" prop="dictType">
        <el-select v-model="queryParams.dictType">
          <el-option v-for="item in typeOptions" :key="item.dictId" :label="item.dictName" :value="item.dictType" />
        </el-select>
      </el-form-item>
      <el-form-item label="字典标签" prop="dictLabel">
        <el-input v-model="queryParams.dictLabel" placeholder="请输入字典标签" clearable @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select v-model="queryParams.status" placeholder="数据状态" clearable>
          <el-option v-for="dict in dict.type.sys_normal_disable" :key="dict.value" :label="dict.label" :value="dict.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd" v-hasPermi="['system:dict:add']">新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="handleUpdate" v-hasPermi="['system:dict:edit']">修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete" v-hasPermi="['system:dict:remove']">删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" @click="handleExport" v-hasPermi="['system:dict:export']">导出
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-close" size="mini" @click="handleClose">关闭</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="dataList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="字典编码" align="center" prop="dictCode" />
      <el-table-column label="字典标签" align="center" prop="dictLabel">
        <template slot-scope="scope">
          <span v-if="scope.row.listClass == '' || scope.row.listClass == 'default'">{{ scope.row.dictLabel }}</span>
          <el-tag v-else :type="scope.row.listClass == 'primary' ? '' : scope.row.listClass">{{ scope.row.dictLabel }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="字典键值" align="center" prop="dictValue" />
      <el-table-column label="字典排序" align="center" prop="dictSort" />
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <dict-tag :options="dict.type.sys_normal_disable" :value="scope.row.status" />
        </template>
      </el-table-column>
      <el-table-column label="标的导入文档模板" v-if="queryParams.dictType=='bd_city'">
        <template slot-scope="scope">
          <el-upload ref="upload" class="upload-demo" :action="spuploadImgUrl" :headers="headers" :before-upload="beforeUpload" :on-success="(response)=>uploadVideoPicSuccess(response, scope.row)">
            <el-button size="small" type="primary" v-if="scope.row.id==null && scope.row.fileUrl==null">点击上传</el-button>
            <el-button size="small" type="primary" v-else>点击修改</el-button>
          </el-upload>
        </template>
      </el-table-column>

      <el-table-column label="国家/地区" v-if="queryParams.dictType=='bd_city'">
        <template slot-scope="scope">
          <img :src="scope.row.cityUrl" width="auto" height="50">
        </template>
      </el-table-column>

      <el-table-column :label="queryParams.dictType=='bd_city'?'商标图片':'备注'" align="center" prop="remark" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <img v-if="queryParams.dictType=='bd_city'" :src="scope.row.remark" width="auto" height="50">
          <span v-else>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>

      <el-table-column label="SKUID" prop="spuId" v-if="queryParams.dictType=='bd_city'" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{ scope.row.spuId }}</span>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)" v-hasPermi="['system:dict:edit']">修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-hasPermi="['system:dict:remove']">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />

    <!-- 添加或修改参数配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-form-item label="字典类型">
          <el-input v-model="form.dictType" :disabled="true" />
        </el-form-item>
        <el-form-item label="数据标签" prop="dictLabel">
          <el-input v-model="form.dictLabel" placeholder="请输入数据标签" />
        </el-form-item>
        <el-form-item label="数据键值" prop="dictValue">
          <el-input v-model="form.dictValue" placeholder="请输入数据键值" />
        </el-form-item>
        <el-form-item label="样式属性" prop="cssClass">
          <el-input v-model="form.cssClass" placeholder="请输入样式属性" />
        </el-form-item>
        <el-form-item label="显示排序" prop="dictSort">
          <el-input-number v-model="form.dictSort" controls-position="right" :min="0" />
        </el-form-item>
        <el-form-item label="回显样式" prop="listClass">
          <el-select v-model="form.listClass">
            <el-option v-for="item in listClassOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="国家/地区" prop="cityUrl" v-if="queryParams.dictType=='bd_city'">
          <el-upload style="display: inline-block" accept=".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO" :action="spuploadImgUrl" list-type="picture-card" :headers="headers" :file-list="Pics" name="file"
            :on-success="uploadUrlPicSuccess">
            <el-tooltip effect="dark" content="建议图片尺寸800px * 800px" placement="right">
              <img v-if="form.cityUrl !=null " :src="form.cityUrl" style="width: 100%;height: 100%">
              <i v-else class="el-icon-plus"></i>
            </el-tooltip>
          </el-upload>
        </el-form-item>

        <el-form-item label="商标图片" prop="remark" v-if="queryParams.dictType=='bd_city' && form.dictValue!='001' ">
          <el-upload style="display: inline-block" accept=".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO" :action="spuploadImgUrl" list-type="picture-card" :headers="headers" :file-list="Pics" name="file"
            :on-success="uploadPicSuccess">
            <el-tooltip effect="dark" content="建议图片尺寸800px * 800px" placement="right">
              <img v-if="form.remark != null " :src="form.remark" style="width: 100%;height: 100%">
              <i v-else class="el-icon-plus"></i>
            </el-tooltip>
          </el-upload>
        </el-form-item>
        <el-form-item>
          <div v-if="queryParams.dictType=='bd_city' ">
            图片格式为:(jpeg,png,gif,ico,JPEG,PNG,GIF,ICO)
          </div>
        </el-form-item>

        <el-form-item label="绑定转让sku" prop="transferSkuId" v-if="queryParams.dictType =='bd_city'">
          <el-input v-model="form.transferSkuId" placeholder="请输入转让sku" />
        </el-form-item>
        <span v-else></span>

        <el-form-item label="转让费用" prop="transferPrice"  v-if="queryParams.dictType =='bd_city'">
          <el-input v-model="form.transferPrice" :disabled="true"  placeholder="转让费用抓取企业购数据" />
        </el-form-item>
        <span v-else></span>

        <el-form-item label="绑定sku" prop="spuId" v-if="queryParams.dictType =='bd_city'">
          <el-input v-model="form.spuId" placeholder="请输入sku" />
        </el-form-item>
        <span v-else></span>

        <el-form-item label="状态" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio v-for="dict in dict.type.sys_normal_disable" :key="dict.value" :label="dict.value">{{ dict.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注" prop="remark" v-if="queryParams.dictType!='bd_city'">
          <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listData,
  getData,
  delData,
  addData,
  updateData,
} from "@/api/system/dict/data";
import {
  optionselect,
  listType,
  getType,
  insertBdSysFile,
} from "@/api/system/dict/type";
import { getToken } from "@/utils/auth";

export default {
  name: "Data",
  dicts: ["sys_normal_disable"],
  data() {
    return {
      spuploadImgUrl: process.env.VUE_APP_BASE_API + "/file/upload", // 上传的图片服务器地址
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      Pics: [], // 视频封面图片
      imageDialogVisible: false,
      imageUrlDialogVisible: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 字典表格数据
      dataList: [],
      // 默认字典类型
      defaultDictType: "",
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 数据标签回显样式
      listClassOptions: [
        {
          value: "default",
          label: "默认",
        },
        {
          value: "primary",
          label: "主要",
        },
        {
          value: "success",
          label: "成功",
        },
        {
          value: "info",
          label: "信息",
        },
        {
          value: "warning",
          label: "警告",
        },
        {
          value: "danger",
          label: "危险",
        },
      ],
      // 类型数据字典
      typeOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        spuId: [{ required: true, message: "请绑定sku", trigger: "blur" }],
        dictLabel: [
          { required: true, message: "数据标签不能为空", trigger: "blur" },
        ],
        dictValue: [
          { required: true, message: "数据键值不能为空", trigger: "blur" },
        ],
        dictSort: [
          { required: true, message: "数据顺序不能为空", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    const dictId = this.$route.params && this.$route.params.dictId;
    this.getType(dictId);
    this.getTypeList();
  },
  methods: {
    // 上传图片成功
    uploadPicSuccess(res, file) {
      this.form.remark = res.data.url;
    },

    // 上传图片成功
    uploadUrlPicSuccess(res, file) {
      console.log("res-----------------", res);
      console.log("file-----------------", file);
      this.form.cityUrl = res.data.url;
    },

    selectChange() {
      this.handleQuery();
    },
    /** 查询字典类型详细 */
    getType(dictId) {
      getType(dictId).then((response) => {
        this.queryParams.dictType = response.data.dictType;
        this.defaultDictType = response.data.dictType;
        this.getList();
      });
    },
    /** 查询字典类型列表 */
    getTypeList() {
      listType().then((response) => {
        this.typeOptions = response.rows;
      });
      /* getDictOptionselect().then(response => {
        this.typeOptions = response.data;
      });*/
    },
    /** 查询字典数据列表 */
    getList() {
      this.loading = true;
      listData(this.queryParams).then((response) => {
        this.dataList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        dictCode: undefined,
        dictLabel: undefined,
        dictValue: undefined,
        cssClass: undefined,
        listClass: "default",
        dictSort: 0,
        status: "0",
        remark: undefined,
        cityUrl: undefined,
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 返回按钮操作 */
    handleClose() {
      const obj = { path: "/system/dict" };
      this.$tab.closeOpenPage(obj);
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.dictType = this.defaultDictType;
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加字典数据";
      this.form.dictType = this.queryParams.dictType;
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.dictCode);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    //上传
    uploadVideoPicSuccess(res, item) {
      var data = {
        id: item.id,
        fileUrl: res.data.url,
        dictCode: item.dictCode,
      };
      insertBdSysFile(data).then((res1) => {
        if (res1.code == 200) {
          this.$message.success("上传成功");
          this.getList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //判断上传类型
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (testmsg != "xls") {
        this.$message.error("上传格式有误");
        return false;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const dictCode = row.dictCode || this.ids;
      getData(dictCode).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = "修改字典数据";
      });
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.form.dictCode != undefined) {
            updateData(this.form).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addData(this.form).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const dictCodes = row.dictCode || this.ids;
      this.$modal
        .confirm('是否确认删除字典编码为"' + dictCodes + '"的数据项？')
        .then(function () {
          return delData(dictCodes);
        })
        .then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        })
        .catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "system/dict/data/export",
        {
          ...this.queryParams,
        },
        `data_${new Date().getTime()}.xlsx`
      );
    },
  },
};
</script>

<style scoped>
/deep/ .el-upload-list {
  display: none !important;
}
</style>
