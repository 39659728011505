<template>
  <div>
    <el-select v-model="classifyNameTwo" placeholder="请选择产品领域" @change="productChange">
      <el-option
        v-for="item in productOptions"
        :key="item.classifyNameTwo"
        :label="item.classifyNameTwo"
        :value="item.classifyNameTwo"
      >
      </el-option>
    </el-select>
    <!-- <el-date-picker
      v-model="productPicker"
      type="datetimerange"
      range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
      @change="productChange">
    </el-date-picker> -->
  </div>
</template>
  
<script>
export default {
  // Component logic here
  props: {
    productOptions: {
      type: Array, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
      default:[],
    },
  },
  data() {
    return {
        classifyNameTwo:'',
        productPicker:[]
    };
  },
  mounted() {
 
  },
  methods: {
    productChange(e){
        console.log('e',e);
        let data={
            classifyNameTwo:this.classifyNameTwo,
            productPicker:this.productPicker
        }
        this.$emit('productChange',data)
    },
  },
};
</script>
<style lang="scss" scoped>
    
</style>