<template>
  <el-row style="min-width:1100px" :gutter="10">
    <el-col :span="24" style="padding:0 15px">
      <div class="person_rank">
        部门排名<span> {{personData.departmentRanking}}</span>
        集团排名<span> {{personData.groupRanking}}</span>
      </div>

    </el-col>
    <el-col :span="8">
      <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj3.png" alt=""> 月度有效业绩</div>
        <div class="card_main"> <el-progress class="progress_item" :width="150" :stroke-width="15" type="circle" :percentage="Number((personData.effectiveAchievementMonth*100).toFixed(2))"></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">本月目标业绩</div>
              <div class="bcdi_num">{{personData.monthlyTarget | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">本月有效业绩</div>
              <div class="bcdi_num hot">{{personData.effectivePriceMonth | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">月度实际业绩</div>
              <div class="bcdi_num">{{Number((personData.actualPriceSumMonth)).toFixed(2) | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">本月退单业绩</div>
              <div class="bcdi_num">{{Number((personData.refundPriceSumMonth+personData.depositRefundPriceSumMonth)).toFixed(2) | numberWithCommas}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card" header="本周目标业绩">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj3.png" alt=""> 本周有效业绩</div>
        <div class="card_main"> <el-progress color="#817fff" class="progress_item" :width="150" :stroke-width="15" type="circle" :percentage="Number((personData.effectiveAchievementWeek*100).toFixed(2))"></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">本周目标业绩</div>
              <div class="bcdi_num">{{personData.weekTarget | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">本周有效业绩</div>
              <div class="bcdi_num hot">{{personData.effectivePriceWeek | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">周度实际业绩</div>
              <div class="bcdi_num">{{personData.actualPriceSumWeek | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">本周退单业绩</div>
              <div class="bcdi_num">{{(personData.refundPriceSumWeek+personData.depositRefundPriceSumWeek).toFixed(2) | numberWithCommas}}</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card" header="日目标业绩">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj3.png" alt=""> 日有效业绩</div>
        <div class="card_main"> <el-progress color="#ff5f09" class="progress_item" :width="150" :stroke-width="15" type="circle" :percentage="Number((personData.effectiveAchievementDay*100).toFixed(2))"></el-progress>
          <div class="bc_data_right">
            <div class="bcd_item">
              <div class="bcdi_tle">日目标业绩</div>
              <div class="bcdi_num">{{personData.dailyTarget | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">日有效业绩</div>
              <div class="bcdi_num hot">{{personData.effectivePriceDay | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">日实际业绩</div>
              <div class="bcdi_num">{{personData.actualPriceSumDay | numberWithCommas}}</div>
            </div>
            <div class="bcd_item">
              <div class="bcdi_tle">本日退单业绩</div>
              <div class="bcdi_num">{{(personData.refundPriceSumDay+personData.depositRefundPriceSumDay).toFixed(2) | numberWithCommas}}</div>
            </div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :span="16">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card2" header="退单占比">
            <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj2.png" alt=""> 实际业绩数据</div>
            <div class="progress_con" id="refundChart">

            </div>

          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="hover" :body-style="{ padding: '10px' }" class="box-card2" header="签单产品占比">
            <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj1.png" alt=""> 签单产品占比（签单占比排名前五产品）</div>
            <div id="prodrate"></div>
          </el-card>

        </el-col>
      </el-row>
      <el-card shadow="hover" :body-style="{ padding: '10px' }" class="main-card" header="签单趋势">
        <div class="card_head" slot="header"><img src="https://oss.71go.com/oa0513/yj5.png" alt=""> 签单趋势</div>
        <div class="data_change">
          <el-radio-group @input="changeChartType" size="mini" v-model="trendData.chartType" style="margin-right:5px">
            <el-radio-button :label="0">本周</el-radio-button>
            <el-radio-button :label="1">本月</el-radio-button>
            <el-radio-button :label="2">本年</el-radio-button>
          </el-radio-group>
          <el-date-picker :clearable="false" @change="setYearChanged" style="width:100px" size="mini" v-if="trendData.chartType == 2" v-model="setyear" type="year" placeholder="选择年">
          </el-date-picker>
        </div>
        <div id="progress_trend"></div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import {
  findAchievementInfoBySalesmanId,
  findChartBySalesmanId,
  findSaleInfo,
} from "@/api/analysis/data";
import echarts from "echarts";
export default {
  // Component logic here
  props: {
    salesmanId: {
      type: Number, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
  },
  data() {
    return {
      refundOption: {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },

        series: [
          {
            name: "退单比例",
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            label: {
              position: "inner",
              fontSize: 10,
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
          {
            name: "部门实际业绩",
            type: "pie",
            radius: ["55%", "75%"],
            label: {
              position: "inner",
              fontSize: 12,
              formatter: "{b}: {c}% ",
              alignTo: "none",
              bleedMargin: 5,
            },

            data: [],
          },
        ],
      },
      setyear: null,
      personData: {},
      queryData: { salesmanId: null, thisDay: "2024-05-08" },
      trendData: {
        salesmanId: null,
        chartType: 0,
        startTime: "2024-05-01",
        endTime: "2024-05-31",
      },
      prodrateOption: {
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "签单产品占比",
            type: "pie",
            radius: ["40%", "70%"],
            padAngle: 5,
            itemStyle: {
              borderRadius: 20,
              borderColor: "#fff",
              borderWidth: 3,
              color: "#999",
            },
            label: {
              color: "#333",
              show: true,
              position: "left",
              formatter: "{b}: {c}", // 显示名称和值，并添加'件'
              itemStyle: {
                borderRadius: 20,
                borderColor: "#fff",
                borderWidth: 3,
                color: "#333",
              },
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 13,
                fontWeight: "bold",
                color: "#333",
              },
            },
            labelLine: {
              show: true,
            },
            data: [],
          },
        ],
      },
      trendOption: {
        legend: {},
        tooltip: {},
        dataset: {
          source: [],
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#333", // 设置 y 轴文字颜色为 #333
          },
          axisLine: {
            lineStyle: {
              color: "#eee", // 设置横轴颜色为 #666
            },
          },
          axisTick: {
            lineStyle: {
              color: "#eee", // 设置刻度线颜色为 #666
            },
          },
          axisPointer: {
            lineStyle: {
              color: "#eee", // 设置指示器线的颜色为 #eee
            },
          },
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", // 设置分隔线为虚线
              opacity: 0.6, // 设置透明度
            },
          },
          axisLabel: {
            color: "#333", // 设置 y 轴文字颜色为 #333
          },
          axisLine: {
            lineStyle: {
              color: "#eee", // 设置横轴颜色为 #666
            },
          },
          axisTick: {
            lineStyle: {
              color: "#eee", // 设置刻度线颜色为 #666
            },
          },
          axisPointer: {
            lineStyle: {
              color: "#eee", // 设置指示器线的颜色为 #eee
            },
          },
        },
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [
          {
            type: "bar",
            itemStyle: {
              color: "#4184f2",
            },
            barWidth: "12%", // Decrease the width of the bars
            barCategoryGap: "30%", // Increase the gap between bar categories
            barGap: "50%", // Increase the gap between bars within the same category
            label: {
              show: true,
              position: [-50, -10], // Show label on right of the bars
            },
          }, // Custom color for the first bar series
          {
            type: "bar",
            itemStyle: { color: "#ff6d00" },
            barWidth: "12%", // Decrease the width of the bars
            barCategoryGap: "10%", // Increase the gap between bar categories
            barGap: "20%", // Increase the gap between bars within the same category
            label: {
              show: true,
              position: [10, -20], // Show label on top of the bars
            },
          }, // Custom color for the second bar series
        ],
      },
    };
  },
  mounted() {
    this.queryData.salesmanId = this.trendData.salesmanId = this.salesmanId;

    this.getSaleInfo();
    this.getDetail();
    this.gettrendData();
    this.initChart();
  },
  methods: {
    async getSaleInfo() {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );

      this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
        firstDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-01`;
      this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
        lastDayOfMonth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
      const colors = [
        "#817fff",
        "#4184f2",
        "#17a3ff",
        "#fac74f",
        "#7d82b8",
        "#5f6cad",
        "#4394e1",
        "#2fc6d7",
        "#88a0d2",
        "#6bb3cc",
        "#4dc6c6",
        "#30d9bc",
        "#f5c272",
        "#f4d491",
        "#f6e7b4",
        "#9977ad",
        "#b28fb3",
        "#c8a7b8",
        "#dfbfdc",
        "#86a873",
        "#a6c187",
      ];
      let res = await findSaleInfo(this.trendData);
      if (res.code == 200) {
        let arr = res.data.map((item, index) => {
          return {
            value: item.actualPriceSum,
            itemStyle: { color: colors[index] },
            name: item.classifyNameTwo,
          };
        });
        this.prodrateOption.series[0].data = arr;
        this.initChart();
      }
    },
    initrefundChart() {
      var refundDom = document.getElementById("refundChart");
      var refund = echarts.init(refundDom);
      refund.setOption(this.refundOption);
    },

    getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      let month = today.getMonth() + 1;
      let day = today.getDate();

      // 补零操作
      month = month < 10 ? "0" + month : month;
      day = day < 10 ? "0" + day : day;

      return `${year}-${month}-${day}`;
    },
    initChart() {
      var chartDom = document.getElementById("prodrate");
      var myChart = echarts.init(chartDom);
      myChart.setOption(this.prodrateOption);
    },
    initTrendChart() {
      var chartDom2 = document.getElementById("progress_trend");
      var myChart2 = echarts.init(chartDom2);
      myChart2.setOption(this.trendOption);
    },
    async getDetail() {
      this.queryData.thisDay = this.getCurrentDate();
      let res = await findAchievementInfoBySalesmanId(this.queryData);
      if (res.code == 200) {
        this.personData = res.data;
        this.refundOption.series[0].data = [
          {
            value: res.data.depositRefundPriceSumMonth,
            name: "预存款",
            itemStyle: { color: "#4184f2" },
          },
          {
            value: res.data.refundPriceSumMonth,
            name: "退款",
            itemStyle: { color: "#17a3ff" },
          },
        ];
        this.refundOption.series[1].data = [
          {
            value: Number(
              (this.personData.monthlyAchievement * 100).toFixed(2)
            ),
            name: "完成",
            itemStyle: { color: "#3e7aff" },
          },
          {
            value: Number(
              (100 - this.personData.monthlyAchievement * 100).toFixed(2)
            ),
            name: "未完成",
            itemStyle: { color: "#adceff" },
            label: {
              show: false, // 设置为true以显示label
            },
          },
        ];
        this.initrefundChart();
      }
    },
    async gettrendData() {
      let res = await findChartBySalesmanId(this.trendData);

      if (res.code == 200) {
        let week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
        switch (this.trendData.chartType) {
          case 0:
            let source = res.data.map((item, indexCheck) => {
              return [week[indexCheck], item.targetSum, item.effectivePrice];
            });
            source.unshift(["target", "业绩目标", "有效业绩"]);

            this.trendOption.dataset.source = source;
            this.initTrendChart();

            break;
          case 1:
            let weekNum = ["第一周", "第二周", "第三周", "第四周", "第五周"];
            let source2 = res.data.map((item, indexCheck) => {
              return [
                weekNum[item.weekNumber - 1],
                item.targetSum,
                item.effectivePrice,
              ];
            });
            source2.unshift(["target", "业绩目标", "有效业绩"]);

            this.trendOption.dataset.source = source2;
            this.initTrendChart();

            break;
          case 2:
            let source3 = res.data.map((item, indexCheck) => {
              return [item.monthTime, item.targetSum, item.effectivePrice];
            });
            source3.unshift(["target", "业绩目标", "有效业绩"]);

            this.trendOption.dataset.source = source3;
            this.initTrendChart();

            break;

          default:
            break;
        }
      }
    },
    changeChartType(value) {
      if (value == 0) {
        // 本周
        this.trendData.startTime = "";
        this.trendData.endTime = "";
      } else if (value == 1) {
        // 本月
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        );
        const lastDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        );

        this.trendData.startTime = `${firstDayOfMonth.getFullYear()}-${(
          firstDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-01`;
        this.trendData.endTime = `${lastDayOfMonth.getFullYear()}-${(
          lastDayOfMonth.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")}-${lastDayOfMonth.getDate()}`;
      } else if (value == 2) {
        // 本年
        const currentYear = new Date().getFullYear();
        this.trendData.startTime = `${currentYear}-01-01`;
        this.trendData.endTime = `${currentYear}-12-31`;
        this.setyear = new Date();
      }
      this.gettrendData();
    },
    setYearChanged(value) {
      const selectedYear = new Date(value).getFullYear();
      const firstDayOfYear = `${selectedYear}-01-01`;
      const lastDayOfYear = `${selectedYear}-12-31`;
      this.trendData.startTime = firstDayOfYear;
      this.trendData.endTime = lastDayOfYear;
      this.gettrendData();
    },
  },
};
</script>
<style lang="scss" scoped>
.box-card {
  height: 250px;
  margin: 20px 10px;
  .card_main {
    display: flex;
    .bc_data_right {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      border-left: 1px solid #e6ebf5;
      padding: 10px;
      min-width: 263px;
      .bcd_item {
        width: 45%;
        margin: 8px 6px;
        min-width: 100px;
        .bcdi_tle {
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #666666;
        }
        .hot {
          color: #ff3b3b !important;
        }
        .bcdi_num {
          font-family: DIN, DIN;
          font-weight: 500;
          font-size: 22px;
          color: #333333;
          margin-top: 3px;
        }
      }
    }
  }
}
.box-card2 {
  height: 250px;
  margin: 20px 0px;
  margin-right: 10px;
}
.main-card {
  height: 522px;
  position: relative;
}
.progress_item {
  margin: 10px;
}
.progress_con {
  display: flex;
  justify-content: center;
}
#progress_trend {
  height: 455px;
  width: 100%;
}
#prodrate {
  height: 185px;
  width: 100%;
}
#refundChart {
  height: 185px;
  width: 100%;
}
.data_change {
  position: absolute;
  right: 91px;
  top: 42px;
  z-index: 99999;
  display: flex;
  align-items: center;
}
.person_rank {
  transform: scale(0.85);
  display: flex;
  align-items: center;
  width: 309px;
  height: 50px;
  background: url("https://oss.71go.com/oa0513/yj4.png");
  background-size: contain;
  background-repeat: no-repeat;
  justify-content: space-around;
  color: #333333;
  padding-left: 30px;
  position: fixed;
  top: 0;
  left: 207px;
  z-index: 999;
  span {
    display: inline-block;
    color: #b47614;
    transform: translateY(1px);
    font-weight: 600;
    font-size: 18px;
  }
}
.card_head {
  color: #333;
  display: flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
</style>
<style>
.el-card__header {
  border-bottom: none;
}
</style>