var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper", class: { isMobileWrapper: _vm.isMobile } },
    [
      _c(
        "div",
        {
          staticClass: "header",
          style: { height: _vm.isMobile ? "auto" : "130px" },
        },
        [
          !_vm.isMobile
            ? _c("img", {
                attrs: {
                  src: "https://oss.71go.com/2023/08/30/ff134da2a28f40ca91c04bb9fdb62c21.png",
                  alt: "",
                },
              })
            : _vm._e(),
          _c("span", { staticClass: "title" }, [
            _vm._v("司盟企服证件智能处理工具"),
          ]),
        ]
      ),
      _c("div", { staticClass: "idCardAll" }, [
        _c("div", { staticClass: "idCard" }, [
          _c(
            "div",
            { staticClass: "idcard1" },
            [
              _c(
                "el-upload",
                {
                  ref: "idCard1",
                  staticClass: "editor-left",
                  attrs: {
                    action: "#",
                    "list-type": "picture-card",
                    disabled: !!_vm.imageUrl,
                    "on-change": _vm.handleChange,
                    "auto-upload": false,
                    accept: ".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO,.jpg",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function (ref) {
                        var file = ref.file
                        return _c("div", {}, [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: { src: file.url, alt: "" },
                          }),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(
                                        file,
                                        1
                                      )
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(file, 1)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  !_vm.imageUrl
                    ? _c("div", { staticClass: "idInfo" }, [
                        _c("img", {
                          attrs: {
                            src: "https://oss.71go.com/douyin/oumeng/o5.png",
                            alt: "",
                          },
                        }),
                        _c("img", {
                          staticClass: "photo",
                          attrs: {
                            src: "https://oss.71go.com/douyin/oumeng/photo.png",
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "text" }, [_vm._v("上传人像面")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "idcard1", staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-upload",
                {
                  ref: "idCard2",
                  staticClass: "editor-right",
                  attrs: {
                    action: "#",
                    "list-type": "picture-card",
                    disabled: !!_vm.imageUrl1,
                    "on-change": _vm.handleChange1,
                    "auto-upload": false,
                    accept: ".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO,.jpg",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "file",
                      fn: function (ref) {
                        var file = ref.file
                        return _c("div", {}, [
                          _c("img", {
                            staticClass: "el-upload-list__item-thumbnail",
                            attrs: { src: file.url, alt: "" },
                          }),
                          _c(
                            "span",
                            { staticClass: "el-upload-list__item-actions" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-preview",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handlePictureCardPreview(file)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-zoom-in" })]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "el-upload-list__item-delete",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRemove(file, 2)
                                    },
                                  },
                                },
                                [_c("i", { staticClass: "el-icon-delete" })]
                              ),
                            ]
                          ),
                        ])
                      },
                    },
                  ]),
                },
                [
                  !_vm.imageUrl1
                    ? _c("div", { staticClass: "idInfo" }, [
                        _c("img", {
                          attrs: {
                            src: "https://oss.71go.com/2024/07/17/17e1791d71fe4b889b0dc6f3c4b50fad.png",
                            alt: "",
                          },
                        }),
                        _c("img", {
                          staticClass: "photo",
                          attrs: {
                            src: "https://oss.71go.com/douyin/oumeng/photo.png",
                            alt: "",
                          },
                        }),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "text" }, [_vm._v("上传国徽面")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "danger" }, on: { click: _vm.reset } },
                [_vm._v("重置")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !(
                      _vm.imageUrl &&
                      _vm.imageUrl1 &&
                      _vm.canvasImg == ""
                    ),
                  },
                  on: { click: _vm.getCanvas },
                },
                [_vm._v("拼接图片")]
              ),
            ],
            1
          ),
        ]),
        _vm.canvasImg
          ? _c(
              "div",
              {
                staticClass: "idCard",
                style: { "margin-left": _vm.isMobile ? "" : "30px" },
              },
              [
                _c("div", { staticClass: "idcard1" }, [
                  _c(
                    "div",
                    [
                      _c("el-image", {
                        staticStyle: { width: "320px" },
                        attrs: {
                          src: _vm.canvasImg,
                          "preview-src-list": [_vm.canvasImg],
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "text" }, [
                    _vm._v(
                      _vm._s(
                        _vm.isWxMobile
                          ? "请长按保存图片到本地相册"
                          : "拼接后的图片"
                      )
                    ),
                  ]),
                ]),
                !_vm.isWxMobile
                  ? _c(
                      "div",
                      { staticClass: "btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "primary",
                              disabled: !_vm.canvasImg,
                            },
                            on: { click: _vm.uploadImg },
                          },
                          [_vm._v("下载拼接图片")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }