<template>
    <div class="wrapper" :class="{'isMobileWrapper': isMobile}">
        <div class="header" :style="{height: isMobile ? 'auto' : '130px'}">
            <img v-if="!isMobile" src="https://oss.71go.com/2023/08/30/ff134da2a28f40ca91c04bb9fdb62c21.png" alt="">
            <span class="title">司盟企服证件智能处理工具</span>
        </div>
        <div class="idCardAll">
            <div class="idCard">
                <div class="idcard1">
                    <el-upload
                        class="editor-left"
                        action="#"
                        list-type="picture-card"
                        :disabled="!!imageUrl"
                        :on-change="handleChange"
                        :auto-upload="false"
                        ref="idCard1"
                        accept=".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO,.jpg"
                    >
                        <div class="idInfo" v-if="!imageUrl">
                            <img src="https://oss.71go.com/douyin/oumeng/o5.png" alt="">
                            <img class="photo" src="https://oss.71go.com/douyin/oumeng/photo.png" alt="">
                        </div>
                        <div slot="file" slot-scope="{file}">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                                <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(file,1)"
                                >
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file,1)"
                                >
                                <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <div class="text">上传人像面</div>
                </div>
                <div class="idcard1" style="margin-top: 20px;">
                    <el-upload
                        class="editor-right"
                        action="#"
                        list-type="picture-card"
                        :disabled="!!imageUrl1"
                        :on-change="handleChange1"
                        :auto-upload="false"
                        ref="idCard2"
                        accept=".jpeg,.png,.gif,.ico,.JPEG,.PNG,.GIF,.ICO,.jpg"
                    >
                        <div class="idInfo" v-if="!imageUrl1">
                            <img src="https://oss.71go.com/2024/07/17/17e1791d71fe4b889b0dc6f3c4b50fad.png" alt="">
                            <img class="photo" src="https://oss.71go.com/douyin/oumeng/photo.png" alt="">
                        </div>
                        <div slot="file" slot-scope="{file}">
                            <img
                                class="el-upload-list__item-thumbnail"
                                :src="file.url" alt=""
                            >
                            <span class="el-upload-list__item-actions">
                                <span
                                    class="el-upload-list__item-preview"
                                    @click="handlePictureCardPreview(file)"
                                >
                                    <i class="el-icon-zoom-in"></i>
                                </span>
                                <span
                                class="el-upload-list__item-delete"
                                @click="handleRemove(file,2)"
                                >
                                <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <div class="text">上传国徽面</div>
                </div>
                <div class="btn">
                    <el-button type="danger" @click="reset">重置</el-button>
                    <el-button type="primary" :disabled="!(imageUrl && imageUrl1 && canvasImg == '')" @click="getCanvas">拼接图片</el-button>
                </div>
            </div>
            <div class="idCard" v-if="canvasImg" :style="{'margin-left': isMobile ? '' : '30px'}">
                <div class="idcard1">
                    <div>
                        <el-image
                            style="width: 320px;"
                            :src="canvasImg"
                            :preview-src-list="[canvasImg]"
                            >
                        </el-image>
                        <!-- <img :src="canvasImg" style="width: 320px;" alt=""> -->
                    </div>
                    <div class="text">{{isWxMobile ? '请长按保存图片到本地相册' : '拼接后的图片'}}</div>
                </div>
                <div class="btn" v-if="!isWxMobile">
                    <el-button type="primary" :disabled="!canvasImg" @click="uploadImg">下载拼接图片</el-button>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            uploadApi: process.env.VUE_APP_BASE_API + "/file/simeng/api/upload", // 上传的图片服务器地址
            imageUrl: '',
            imageUrl1: '',
            loading: null,
            canvasImg: '',
            canvas: '',
            fileList: [],
            dialogVisible: false,
            dialogImageUrl: '',
            isMobile: false,
            isWxMobile: false
        }
    },
    mounted(){
        this.isWxMobile = this.isWeiXin();
    },
    methods: {
        isWeiXin() {
            const userAgent = navigator.userAgent;

            // 判断是否为移动设备
            let isMobile = /iPhone|iPad|iPod|Android|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
            this.isMobile = isMobile;
            // 判断是否为微信浏览器
            let isWeChat = /MicroMessenger/i.test(userAgent);

            // 判断是否为企业微信浏览器
            let isEnterpriseWeChat = /wxwork/i.test(userAgent);
            return (isMobile && isWeChat) || (isMobile && isEnterpriseWeChat)
        },
        // 重置
        reset(){
            this.imageUrl = "";
            this.imageUrl1 = "";
            this.$refs.idCard1.clearFiles();
            this.$refs.idCard2.clearFiles();
            this.canvasImg = "";
            let html = document.getElementsByClassName('editor-left');
            html[0].getElementsByClassName('el-upload')[0].style.display = "block";
            let html1 = document.getElementsByClassName('editor-right');
            html1[0].getElementsByClassName('el-upload')[0].style.display = "block";
        },
        handlePictureCardPreview(file){
            this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        handleChange1(file, fileList){
            this.fileList[1] = file;
            this.imageUrl1 = file.url;
            if(fileList.length > 0){
                let html = document.getElementsByClassName('editor-right');
                html[0].getElementsByClassName('el-upload')[0].style.display = "none";
            }
        },
        handleChange(file, fileList){
            this.fileList[0] = file;
            this.imageUrl = file.url;
            if(fileList.length > 0){
                let html = document.getElementsByClassName('editor-left');
                html[0].getElementsByClassName('el-upload')[0].style.display = "none";
            }
        },
        getBase64(data){
            return new Promise((resolve,reject)=>{
                const blob = new Blob([data],{ type:"image/png"});// 必须指定type类型
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload=()=>resolve(reader.result);
                reader.onerror=(error)=>reject(error);
            })
        },
        getCanvas(){
            this.loading = this.$loading({
                lock: true,
                text: "拼接中...",
                spinner: "el-icon-loading",
                background: "rgba(255, 255, 255, 0.7)",
            });
              // 创建一个新的 FormData 对象
            const formData = new FormData();
            // 添加参数到 FormData 对象
            let list = this.fileList;
            for (let i = 0; i < list.length; i++) {
                formData.append('files', list[i].raw);
            }
            // if (list[0]) {
            //     formData.append('image1', list[0]);
            // }
            // if (list[1]) {
            //     formData.append('image2', list[1]);
            // }
            console.log(formData,'formDataformData');
            // let baseUrl = process.env.VUE_APP_IDCARD_API;
            let url ='/modelScopeApi/id_card_recognition/process'
            axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                responseType: 'blob', // 确保响应类型为 blob
            })
                .then(response => {
                    this.loading.close();
                    // 处理响应数据
                    console.log('处理响应数据', response);
                    const blob = response.data; // 获取响应数据
                    // this.canvasImg = URL.createObjectURL(blob); // 创建一个可用的 URL
                    this.blobToDataURI(blob)
                })
                .catch(error => {
                    // 处理错误
                    if(this.loading){
                        this.loading.close();
                    }
                    console.log('处理错误', error.message,error.status);
                    if(error.message.indexOf('503') > -1){
                        this.$message.warning('当前正在处理其他用户的拼接任务，请稍等10秒后再试。感谢您的耐心！')
                    }
                });
        },
        blobToDataURI(blob, callback) {
            let _this = this;
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = function (e) {
                // callback(e.target.result);
                _this.canvasImg = e.target.result;
            };
        },
        async uploadImg(){
            const imgUrl = this.canvasImg;
            const response = await fetch(imgUrl)
            const blob = await response.blob()
            // 创建下载链接
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = url
            link.download = 'image.png'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            // 释放 Blob URL
            window.URL.revokeObjectURL(url)
        },
        drawBase64Image(image1,image2) {
            var canvas = this.$refs.canvas;
            canvas.width = 794;
            canvas.height = 1123;
            var ctx = canvas.getContext("2d");
            // 设置填充颜色为白色
            ctx.fillStyle = '#FFFFFF';
            
            // 填充整个Canvas
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            // 绘制第一张图片
            ctx.drawImage(image1, 200, 100, 240, 151);
            
            // 绘制第二张图片（在第一张图片上面）
            ctx.drawImage(image2, 200, 300, 240, 151);
            var dataURL = canvas.toDataURL("image/png");
            return dataURL;
        },
        getCanvasInfo(){
            const image1 = new Image();
            image1.src = this.imageUrl; // 第一张图片路径
            image1.referrerPolicy = "no-referrer"
            image1.crossOrigin = "Anonymous"; // 如果图片需要CORS，设置这个属性
            image1.onload = () => {
                const image2 = new Image();
                image2.src = this.imageUrl1; // 第二张图片路径
                image2.referrerPolicy = "no-referrer"
                image2.crossOrigin = "Anonymous"; // 如果图片需要CORS，设置这个属性
                image2.onload = () => {
                    this.uploadUrl(this.drawBase64Image(image1,image2));
                };
            };
        },
        uploadUrl(urls){
            // 创建一个新的 FormData 对象
          const formData = new FormData();
          // 添加参数到 FormData 对象
          formData.append('data', urls);
          formData.append('isAll', true);
          let baseUrl = process.env.VUE_APP_BASE_API;
          let url = baseUrl + '/file/simeng/api/uploadFileBase64ByParam'
          axios.post(url, formData, {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          })
              .then(response => {
                  // 处理响应数据
                  console.log('处理响应数据', response);
                  if (response.data.code == 200) {
                    if(this.loading){
                        this.loading.close();
                    }
                    this.canvasImg =  response.data.data.urlPath;
                  }
              })
              .catch(error => {
                  // 处理错误
                  if(this.loading){
                        this.loading.close();
                    }
                  console.log('处理错误', error);
              });
      },
      handleRemove(file,type) {
        if(type == 1){
            this.imageUrl = '';
            this.$refs.idCard1.clearFiles();
            let html = document.getElementsByClassName('editor-left');
            html[0].getElementsByClassName('el-upload')[0].style.display = "block";
        }else{
            this.imageUrl1 = '';
            this.$refs.idCard2.clearFiles();
            let html = document.getElementsByClassName('editor-right');
            html[0].getElementsByClassName('el-upload')[0].style.display = "block";
        }
      },
    }
}
</script>
<style scoped lang="scss">
.wrapper{
    margin: 10px auto 0;
    width: 1200px;
    .header{
        display: flex;
        align-items: flex-start;
        height: 130px;
        img{
            width: 220px;
        }
        .title{
            margin: 10px 0 0 20px;
            padding-left: 20px;
            color: #666;
            font-size: 26px;
        }
    }
}
.idCardAll{
    display: flex;
    width: 670px;
    margin: 30px auto 0;
}
.idcard1{
    font-size: 16px;
    text-align: center;
    width: fit-content;
    background-color: #F6F7FB;
    padding: 12px 20px;
    height: fit-content;
    ::v-deep .idInfo{
        position: relative;
        width: fit-content;
        width: 280px;
        height: 175px;
        img{
            width: 280px;
        height: 175px;
        }
        .photo{
            width: 50px;
            height: 50px;
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
        }
        .el-icon-error{
            position: absolute;
            right: 10px;
            top: 6px;
            color: red;
            font-size: 20px;
        }
    }
    .text{
        margin: 10px 0;
    }
}
.btn{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}
.editor-left,.editor-right{
    ::v-deep .el-upload--picture-card{
        width: 280px;
        height: 175px;
        border: none;
        z-index: 11111;
    }
    ::v-deep .el-upload-list--picture-card .el-upload-list__item{
        width: 280px;
        height: 175px;
        border: none;
    }
    ::v-deep .el-upload-list__item{
        transition:none !important;
        -webkit-transition:nonne !important;
    }
    ::v-deep .el-upload-list__item-name{
        transition:none !important;
        -webkit-transition:nonne !important;
    }
}
.isMobileWrapper{
    width: 100%!important;
    .idCardAll{
        width: 100%!important;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .btn{
        margin-top: 30px!important;
    }
    .idCard:last-child{
        margin-top: 20px!important;
    }
}
</style>