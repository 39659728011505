<template>
    <div class="hongkongShow">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="关键词">
                <el-input v-model="formInline.cardName" placeholder="请输入关键词"></el-input>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
            </el-form-item>
        </el-form>

        <div class="content">
            <div class="contentLeft">
                <el-table ref="multipleTableLeft" v-if="!searchTable" v-loading="loading" :data="tableData1" border style="width: 100%"
                    :header-cell-style="hearderStyle" :cell-style="cellStyle" tooltip-effect="dark" height="700">

                    <el-table-column prop="cardCh" label="公司名稱（中文）">
                    </el-table-column>
                    <el-table-column prop="cardEn" label="公司名稱（英文）">
                    </el-table-column>

                </el-table>
                <div v-else >
                    <div style="margin: 10px 0;">檢索結果：已為您檢索到 {{tableData3.length}} 條結果</div>
                    <el-table  v-loading="loading" :data="tableData3" border style="width: 100%"
                        :header-cell-style="hearderStyle" :cell-style="cellStyle" tooltip-effect="dark" :height="tableData3.length>=15?'700':''">
    
                        <el-table-column prop="cardCh" label="公司名稱（中文）">
                        </el-table-column>
                        <el-table-column prop="cardEn" label="公司名稱（英文）">
                        </el-table-column>
    
                    </el-table>
                    <div style="margin: 10px 0; display: flex; justify-content: flex-end;">

                        <el-button  type="primary" @click="initShow">返回自动播放</el-button>
                    </div>
                </div>
            </div>
            <div>
                <el-table ref="multipleTableRight" v-loading="loading" :data="tableData2" border style="width: 100%"
                    :header-cell-style="hearderStyle" :cell-style="cellStyle" tooltip-effect="dark" height="700">

                    <el-table-column prop="cardCh" label="公司名稱（中文）">
                    </el-table-column>
                    <el-table-column prop="cardEn" label="公司名稱（英文）">
                    </el-table-column>

                </el-table>
            </div>
        </div>



    </div>
</template>

<script>
import { allList } from "@/api/operation/companyCard";
import { tableScroll } from '@/utils/tableScroll'
export default {
    data() {
        return {
            loading: false,
            searchTable: false,
            formInline: {
                cardName: null,
            },
            currentPage: 1,
            total: 0,
            pageSize: 20,
            pageSizes: [20, 50, 100, 200, 300, 400],
            multipleSelection: [],
            tableData: [
                {
                    registerNum: '测试1',
                    name: '猜猜'
                },
                {
                    registerNum: '测试2',
                    name: '猜猜'
                },
                {
                    registerNum: '测试3',
                    name: '猜猜'
                },
                {
                    registerNum: '测试4',
                    name: '猜猜'
                },
                {
                    registerNum: '测试5',
                    name: '猜猜'
                },
                {
                    registerNum: '测试6',
                    name: '猜猜'
                },
                {
                    registerNum: '测试7',
                    name: '猜猜'
                },
                {
                    registerNum: '测试8',
                    name: '猜猜'
                },
                {
                    registerNum: '测试9',
                    name: '猜猜'
                },
                {
                    registerNum: '测试1',
                    name: '猜猜'
                },
                {
                    registerNum: '测试2',
                    name: '猜猜'
                },
                {
                    registerNum: '测试3',
                    name: '猜猜'
                },
                {
                    registerNum: '测试4',
                    name: '猜猜'
                },
                {
                    registerNum: '测试5',
                    name: '猜猜'
                },
                {
                    registerNum: '测试6',
                    name: '猜猜'
                },
                {
                    registerNum: '测试7',
                    name: '猜猜'
                },
                {
                    registerNum: '测试8',
                    name: '猜猜'
                },
                {
                    registerNum: '测试9',
                    name: '猜猜'
                },
                {
                    registerNum: '测试1',
                    name: '猜猜'
                },
                {
                    registerNum: '测试2',
                    name: '猜猜'
                },
                {
                    registerNum: '测试3',
                    name: '猜猜'
                },
                {
                    registerNum: '测试4',
                    name: '猜猜'
                },
                {
                    registerNum: '测试5',
                    name: '猜猜'
                },
                {
                    registerNum: '测试6',
                    name: '猜猜'
                },
                {
                    registerNum: '测试7',
                    name: '猜猜'
                },
                {
                    registerNum: '测试8',
                    name: '猜猜'
                },
                {
                    registerNum: '测试9',
                    name: '猜猜'
                },
                {
                    registerNum: '测试1',
                    name: '猜猜'
                },
                {
                    registerNum: '测试2',
                    name: '猜猜'
                },
                {
                    registerNum: '测试3',
                    name: '猜猜'
                },
                {
                    registerNum: '测试4',
                    name: '猜猜'
                },
                {
                    registerNum: '测试5',
                    name: '猜猜'
                },
                {
                    registerNum: '测试6',
                    name: '猜猜'
                },
                {
                    registerNum: '测试7',
                    name: '猜猜'
                },
                {
                    registerNum: '测试8',
                    name: '猜猜'
                },
                {
                    registerNum: '测试9',
                    name: '猜猜'
                },
                {
                    registerNum: '测试1',
                    name: '猜猜'
                },
                {
                    registerNum: '测试2',
                    name: '猜猜'
                },
                {
                    registerNum: '测试3',
                    name: '猜猜'
                },
                {
                    registerNum: '测试4',
                    name: '猜猜'
                },
                {
                    registerNum: '测试5',
                    name: '猜猜'
                },
                {
                    registerNum: '测试6',
                    name: '猜猜'
                },
            ],
            tableData1:[],
            tableData2:[],
            dialogVisible: false,
            dialogContent: '',
            interval: null,
            clientHeight: ""
        }
    },
    created() {
        this.selectConSignMenylist()

    },
    mounted() {
        tableScroll(this.$refs.multipleTableLeft, 25)
        tableScroll(this.$refs.multipleTableRight, 25)
    },
    methods: {
        // 列表查询
        selectConSignMenylist(type) {
            this.loading = true
            let data = {
                cardName: this.formInline.cardName,
            }
            allList(data).then(res => {
                if (res.code == 200) {
                    if (!type) {
                        this.tableData1 = res.data.slice(0,res.data.length/2)
                        this.tableData2 = res.data.slice(res.data.length/2+1,res.data.length)
                    }else{
                        this.searchTable=true
                        this.tableData3 = res.data
                    }
                    this.total = res.data.length
                    console.log('res', res);
                    this.loading = false
                }
            })
        },
       async initShow(){
            this.searchTable=false
            this.formInline.cardName=null
            await this.selectConSignMenylist()
           tableScroll(this.$refs.multipleTableLeft, 25)
        },
        onSubmit() {
            console.log('submit!', this.formInline);
            this.currentPage = 1
            // this.pageSize=null
            this.selectConSignMenylist(1)
        },

        // 表头样式
        hearderStyle() {
            return 'background: #F9F9F9;color:#666666;text-align:center;'
        },
        // 表格样式
        cellStyle() {
            return 'font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;text-align: center;'
        },


    }
}
</script>

<style  scoped lang="scss">
.hongkongShow {
    padding: 20px;
    box-sizing: border-box;
    .content {
      
        padding: 10px;
        display: flex;
        justify-content: space-around;
        // border: 1px solid #c7c7c7;

        div {
            flex: 1;
        }

        .contentLeft {
            margin-right: 15px;



            // --------------------隐藏table gutter列和内容区右侧的空白 start

        }

    }

    ::-webkit-scrollbar {
        //滚动条宽高，如果不需要显示滚动条可设置宽高为0
        width: 0px;
        height: 0px;
    }

    ::-webkit-scrollbar-thumb {
        //滚动条颜色和圆角
        background-color: #dbd9d9;
        border-radius: 3px;
    }

    ::v-deep .el-table th.gutter {
        display: none;
        width: 0
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
    }

    ::v-deep .el-table__body {
        width: 100% !important;
    }
}
</style>
