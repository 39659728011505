<template>
  <div class="table">
    <el-card
      shadow="hover"
      :body-style="{ padding: '10px' }"
      class="box-card2"
      header=""
    >
      <el-table :data="tableData" stripe class="table-box" style="width: 100%;overflow-x:hidden;" max-height="260px"  :summary-method="getSummaries" show-summary>
        <el-table-column prop="salesmanName" label="专员" >
        </el-table-column>
        <el-table-column prop="target" label="目标" >
        </el-table-column>
        <el-table-column prop="actualPriceSum" label="业绩额" >
        </el-table-column>
        <el-table-column prop="productCount" label="完成率">
          <template slot-scope="scope">
            {{getTagetNum(scope.row.actualPriceSum,scope.row.target) }}%
          </template>
        </el-table-column>
        <el-table-column  label="排名">
          <template slot-scope="scope">
            {{ scope.$index+1 }}
          </template> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    departmentId: {
      type: Number, // 假设salesmanId是字符串类型
      required: true, // 是否必须传递prop
    },
    classifyNameTwo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      input: "",
    };
  },
  mounted() {

  },
  methods: {   
    // 完成率计算
    getTagetNum(num, tagetNum) {
      let taget = 0;
      if (tagetNum !== null && tagetNum > 0) {
        taget = (num / tagetNum) * 100;
      }
      return taget.toFixed(2);
    },
    getSummaries(param) {
        const { columns, data } = param;
        console.log('columns',columns);
        console.log('data',data);
        const sums = [];
        // 映射列索引到数据属性
        const columnMapping = {
            1: 'monthActualPriceSum',
            2: 'monthTaget',
            3: 'monthPercentage'
        };
        columns.forEach((column, index) => {
          console.log('column.property',column.property);
          if (index === 0) {
            sums[index] = '合计';
            return;
          }
          if (data && data.length > 0) {
            const fieldName = columnMapping[index];
            if (fieldName && data[0][fieldName] !== undefined) {
                sums[index] = data[0][fieldName];
                return;
            }
          }

          // 如果没有匹配的条件，则设置为空字符串
          sums[index] = ''; 
        });
        console.log('sums',sums);
        return sums;
      }
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
.box-card2 {
  margin: 20px 0px;
  margin-left: 10px;
}
.card_head {
  display: flex;
  /* width: 170px; */
  align-items: center;
  .title {
    width: 130px;
  }
}

.inputbox {
  width: 270px;
  margin-left: auto;
  .input{
    width: 200px;
  }
  .search{
    width: 70px;
  }
}
::v-deep .el-table__header-wrapper tbody td.el-table__cell, 
::v-deep .el-table__footer-wrapper tbody td.el-table__cell{
    background: #FBEBEB !important;
    color: #FF0000 !important;
}
</style>
