import request from '@/utils/request'

// 查询公司水牌信息管理列表
export function listCompanyCard(data) {
  return request({
    url: '/operation/companyCard/list',
    method: 'get',
    params: data
  })
}

// 查询公司水牌信息管理详细
export function getCompanyCard(id) {
  return request({
    url: '/operation/companyCard/' + id,
    method: 'get'
  })
}

// 新增公司水牌信息管理
export function addCompanyCard(data) {
  return request({
    url: '/operation/companyCard',
    method: 'post',
    data: data
  })
}

// 修改公司水牌信息管理
export function updateCompanyCard(data) {
  return request({
    url: '/operation/companyCard',
    method: 'put',
    data: data
  })
}

// 删除公司水牌信息管理
export function delCompanyCard(id) {
  return request({
    url: '/operation/companyCard/' + id,
    method: 'delete'
  })
}
// 获取公司水牌信息管理
export function allList(query) {
  return request({
    url: '/operation/simeng/api/companyCard/allList',
    method: 'get',
    params: query
  })
}
