var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hongkongShow" },
    [
      _c(
        "el-form",
        {
          staticClass: "demo-form-inline",
          attrs: { inline: true, model: _vm.formInline },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "关键词" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入关键词" },
                model: {
                  value: _vm.formInline.cardName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formInline, "cardName", $$v)
                  },
                  expression: "formInline.cardName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "contentLeft" },
          [
            !_vm.searchTable
              ? _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "multipleTableLeft",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData1,
                      border: "",
                      "header-cell-style": _vm.hearderStyle,
                      "cell-style": _vm.cellStyle,
                      "tooltip-effect": "dark",
                      height: "700",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "cardCh", label: "公司名稱（中文）" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "cardEn", label: "公司名稱（英文）" },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("div", { staticStyle: { margin: "10px 0" } }, [
                      _vm._v(
                        "檢索結果：已為您檢索到 " +
                          _vm._s(_vm.tableData3.length) +
                          " 條結果"
                      ),
                    ]),
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticStyle: { width: "100%" },
                        attrs: {
                          data: _vm.tableData3,
                          border: "",
                          "header-cell-style": _vm.hearderStyle,
                          "cell-style": _vm.cellStyle,
                          "tooltip-effect": "dark",
                          height: _vm.tableData3.length >= 15 ? "700" : "",
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: { prop: "cardCh", label: "公司名稱（中文）" },
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "cardEn", label: "公司名稱（英文）" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          margin: "10px 0",
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.initShow },
                          },
                          [_vm._v("返回自动播放")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                ref: "multipleTableRight",
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.tableData2,
                  border: "",
                  "header-cell-style": _vm.hearderStyle,
                  "cell-style": _vm.cellStyle,
                  "tooltip-effect": "dark",
                  height: "700",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "cardCh", label: "公司名稱（中文）" },
                }),
                _c("el-table-column", {
                  attrs: { prop: "cardEn", label: "公司名稱（英文）" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }