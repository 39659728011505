var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container data_bg" },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: "https://oss.71go.com/oa0513/logo.png", alt: "" },
      }),
      _c("div", { staticClass: "fix_nav" }, [
        _c("div", { staticClass: "user-info" }, [
          _c("div", { staticClass: "flex_Ca" }, [
            _c("img", {
              staticClass: "avatar",
              attrs: {
                src: "https://oss.71go.com/pcvip0404/user/pt.png",
                alt: "User Avatar",
              },
            }),
            _c("span", { staticClass: "name" }, [
              _vm._v(_vm._s(_vm.dataSource.salesmanName)),
            ]),
          ]),
          _c(
            "span",
            { staticClass: "update-info" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "20px" },
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.infoVisible = true
                    },
                  },
                },
                [_vm._v("规则说明")]
              ),
              _c("span", { staticClass: "last-update" }, [
                _vm._v("最近一次更新时间：" + _vm._s(_vm.lastUpdate)),
              ]),
              _c("span", { staticClass: "next-update" }, [
                _vm._v("下一次更新时间：" + _vm._s(_vm.nextUpdate)),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-tabs",
        {
          staticStyle: { height: "100%", "padding-top": "40px" },
          attrs: { "tab-position": "left", type: "border-card" },
          on: {
            "tab-click": function ($event) {
              return _vm.addPoint(
                "点击" + _vm.tabs[_vm.activeName - 1] + "报表"
              )
            },
          },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _vm.dataSource.salesmanName
            ? _c(
                "el-tab-pane",
                { attrs: { label: "个人", name: "1" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _c("i", { staticClass: "el-icon-user" }),
                    _vm._v(" 个人"),
                  ]),
                  _vm.activeName == 1
                    ? _c("personData", {
                        attrs: { salesmanId: _vm.dataSource.salesmanId },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tab-pane",
            { attrs: { label: "部门", name: "2" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-date" }),
                _vm._v(" 部门"),
              ]),
              _vm.activeName == 2
                ? _c("departmentData", {
                    attrs: { departmentId: _vm.dataSource.departmentId },
                    on: { addPoint: _vm.addPoint },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "战区", name: "3" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-files" }),
                _vm._v(" 战区"),
              ]),
              _vm.activeName == 3
                ? _c("warzoneData", {
                    attrs: { zoneId: _vm.dataSource.zoneId },
                    on: { addPoint: _vm.addPoint },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "集团", name: "4" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-house" }),
                _vm._v(" 集团"),
              ]),
              _vm.activeName == 4
                ? _c("groupData", { on: { addPoint: _vm.addPoint } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "订单", name: "5" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-s-order" }),
                _vm._v(" 订单"),
              ]),
              _vm.activeName == 5 ? _c("orderData") : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "榜单", name: "6" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v(" 榜单"),
              ]),
              _vm.activeName == 6 ? _c("rankData") : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "产品个人", name: "7" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v(" 产品个人"),
              ]),
              _vm.activeName === "7"
                ? _c("PersonData2", {
                    attrs: { salesmanId: _vm.dataSource.salesmanId },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "产品部门", name: "8" } },
            [
              _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                _c("i", { staticClass: "el-icon-star-off" }),
                _vm._v(" 产品部门"),
              ]),
              _vm.activeName == 8
                ? _c("productsDivision", {
                    attrs: { departmentId: _vm.dataSource.departmentId },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.infoVisible,
            center: "",
            title: "司盟企服营销数据看板计算说明",
          },
          on: {
            "update:visible": function ($event) {
              _vm.infoVisible = $event
            },
          },
        },
        [
          _c("div", [
            _c("h4", [_vm._v("前言：")]),
            _c("p", [
              _vm._v(
                "数据看板以司盟企服全平台订单数据为基础，根据SCRM系统内的订单关联客户、订单关联客服人员等数据进行多维度的统计和展示，便于客服人员、各层级管理层了解营销工作目标达成进度，便于支持各层级管理层组织策略建设、方案建设和管理经营决策。"
              ),
            ]),
            _c("h4", [_vm._v("数据说明：")]),
            _c("h5", [_vm._v("一、数据结果应用")]),
            _c("ul", [
              _c("strong", [_vm._v("荣誉评比:")]),
              _c("li", [_vm._v("个人维度: 评选“销冠”等荣誉.")]),
              _c("li", [_vm._v("团队维度: 评选团队冠军、亚军等.")]),
              _c("li", [
                _vm._v("特定活动: 阶段性营销活动的个人或团队冠军评比."),
              ]),
            ]),
            _c("ul", [
              _c("strong", [_vm._v("考核评估:")]),
              _c("li", [_vm._v("绩效考核: 基于业绩数据进行员工绩效评估.")]),
              _c("li", [_vm._v("激励奖金: 根据业绩达成情况分配奖金.")]),
              _c("li", [_vm._v("职级晋级: 根据业绩达成评估职级升降考核.")]),
            ]),
            _c("h5", [_vm._v("二、数据意义和计算规则")]),
            _c("ol", [
              _c("strong", [_vm._v("业绩目标")]),
              _c("li", [
                _c("p", [
                  _vm._v(
                    "数据意义及应用: 以集团每月计划下达的目标为准，作为当月荣誉评比和考核评估的目标数据."
                  ),
                ]),
                _c("p", [
                  _vm._v(
                    "计算规则: 日目标 = 月目标 / 本月工作日天数; 周目标 = 日目标 * 本周工作天数"
                  ),
                ]),
              ]),
              _c("strong", [_vm._v("本月有效业绩")]),
              _c("li", [
                _c("p", [
                  _vm._v(
                    "数据意义及应用: 指当月有效的新签订单业绩，应用于各类荣誉评比."
                  ),
                ]),
                _c("p", [
                  _vm._v("计算规则: 当月新签单业绩 - 当月订单当月退单的业绩."),
                ]),
              ]),
              _c("strong", [_vm._v("退单业绩")]),
              _c("li", [
                _c("p", [
                  _vm._v(
                    "数据意义及应用：用于核算当月个人/团队/战区/集团的实际业绩，退单业绩无论是因为主观原因还是客观原因造成的，队员、管理层都需要对此结果负责。"
                  ),
                ]),
                _c("p", [_vm._v("计算规则: 所有在本月审批结束的退单业绩.")]),
              ]),
              _c("strong", [_vm._v("实际业绩")]),
              _c("li", [
                _c("p", [
                  _vm._v(
                    "数据意义及应用: 指当月新签在扣除所有当月所有退单业绩后的总业绩，应用于各类考核评估."
                  ),
                ]),
                _c("p", [
                  _vm._v("计算规则: 当月新签单业绩 - 当月所有退单的业绩."),
                ]),
              ]),
              _c("strong", [_vm._v("签单产品占比等其他明细数据")]),
              _c("li", [
                _c("p", [
                  _vm._v(
                    "签单产品分类为该订单内的具体产品在产品中台的一二级分类（产品一级分类：地域（海外、国内）；产品二级分类：产品专业领域（如知识产权、财税审计等））。"
                  ),
                ]),
              ]),
            ]),
          ]),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.infoVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }