var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: " oa_main" },
    [
      _c("div", { staticClass: "noa_box" }, [
        _c(
          "div",
          { staticClass: "nob_left" },
          [
            _vm._m(0),
            !_vm.loading
              ? _c("div", { staticClass: "nobl_user" }, [
                  _c("div", { staticClass: "ui_box" }, [
                    _c("img", {
                      staticClass: "user_img",
                      attrs: { src: _vm.userInfo.avatar, alt: "" },
                    }),
                    _vm.userInfo.gender == 2
                      ? _c("img", {
                          staticClass: "user_sex",
                          attrs: {
                            src: "https://oss.71go.com/noa0906/noa11.png",
                            alt: "",
                          },
                        })
                      : _c("img", {
                          staticClass: "user_sex",
                          attrs: {
                            src: "https://oss.71go.com/noa0906/noa10.png",
                            alt: "",
                          },
                        }),
                  ]),
                  _c("div", { staticClass: "user_status" }, [
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa2.png",
                        alt: "出差",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa7.png",
                        alt: "加班",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa12.png",
                        alt: "外勤",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa23.png",
                        alt: "请假",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "user_hello" }, [
                    _vm._v(
                      _vm._s(_vm.timeMsg) +
                        "好！" +
                        _vm._s(_vm.userInfo.username)
                    ),
                  ]),
                  _c("div", { staticClass: "user_info" }, [
                    _vm._v(
                      _vm._s(
                        _vm.userInfo.entryDay + " 职级：" + _vm.userInfo.rank
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "user_honor" }, [
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa8.png",
                        alt: "进步之星",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa15.png",
                        alt: "闪耀之星",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                    _c("img", {
                      staticClass: "us_img",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa29.png",
                        alt: "优秀员工",
                        title: _vm.imageName,
                      },
                      on: {
                        mouseover: _vm.showTooltip,
                        mouseout: _vm.hideTooltip,
                      },
                    }),
                  ]),
                  _c("div", { staticClass: "user_famous" }, [
                    _vm._v("新的一天继续努力 有精神凡事皆有可能"),
                  ]),
                  _c("div", { staticClass: "fast_entrance" }, [
                    _vm._m(1),
                    _c(
                      "div",
                      { staticClass: "fast_entrance_box" },
                      _vm._l(_vm.functionList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "fe_item",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.goOtherSys(
                                  item.buttonStatus,
                                  index,
                                  item,
                                  1
                                )
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: item.icon, alt: "" } }),
                            _c("div", [_vm._v(_vm._s(item.tabName))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.loading
              ? _c(
                  "el-skeleton",
                  {
                    staticStyle: { width: "240px", "margin-top": "30px" },
                    attrs: { loading: _vm.loading, animated: "" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "template" },
                      [
                        _c("el-skeleton-item", {
                          staticStyle: { width: "240px", height: "240px" },
                          attrs: { variant: "image" },
                        }),
                        _c(
                          "div",
                          { staticStyle: { padding: "14px" } },
                          [
                            _c("el-skeleton-item", {
                              staticStyle: { width: "50%" },
                              attrs: { variant: "p" },
                            }),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-items": "space-between",
                                },
                              },
                              [
                                _c("el-skeleton-item", {
                                  staticStyle: { "margin-right": "16px" },
                                  attrs: { variant: "text" },
                                }),
                                _c("el-skeleton-item", {
                                  staticStyle: { width: "30%" },
                                  attrs: { variant: "text" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "space-between",
                              "flex-wrap": "wrap",
                            },
                          },
                          [
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                            _c("el-skeleton-item", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "margin-top": "10px",
                              },
                              attrs: { variant: "text" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "nob_right" }, [
          _c("div", { staticClass: "nr_top" }, [
            _c("div", { staticClass: "nrt_msg" }, [
              _vm._v(_vm._s(_vm.userInfo.quote)),
            ]),
            _c(
              "div",
              { staticClass: "nrt_act" },
              [
                _c("img", {
                  staticClass: "user_img2",
                  attrs: { src: _vm.userInfo.avatar, alt: "" },
                }),
                _c(
                  "el-dropdown",
                  [
                    _c("img", {
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa37.png",
                        alt: "",
                      },
                    }),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          {
                            nativeOn: {
                              click: function ($event) {
                                return _vm.loginOut()
                              },
                            },
                          },
                          [_vm._v("退出登录")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "nr_bottom" }, [
            _c("div", { staticClass: "nrb_left" }, [
              !!_vm.CmsPopularList
                ? _c("div", { staticClass: "nrbl_top" }, [
                    _c("div", { staticClass: "nrbr_bottom" }, [
                      _vm._m(2),
                      _c("div", { staticClass: "nrbrb_box" }, [
                        _c("div", { staticClass: "nrb_top_box" }, [
                          _c("img", {
                            attrs: { src: _vm.groupNotice[0].url, alt: "" },
                          }),
                          _vm.groupNotice
                            ? _c(
                                "div",
                                {
                                  staticClass: "nrbtb_msg",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.lookDetailitem(
                                        _vm.groupNotice[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "nrbrb_con_top" }, [
                                    _c("span", { staticClass: "spans" }, [
                                      _vm._v(
                                        "【" +
                                          _vm._s(
                                            _vm.getType(_vm.groupNotice[0].type)
                                          ) +
                                          "】" +
                                          _vm._s(_vm.groupNotice[0].title)
                                      ),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.groupNotice[0].releasePerson
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.groupNotice[0].createTime
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "nrbrb_con1" },
                                    _vm._l(
                                      _vm.groupNotice.slice(1, 8),
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.lookDetailitem(item)
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "spans" },
                                              [
                                                _vm._v(
                                                  "【" +
                                                    _vm._s(
                                                      _vm.getType(item.type)
                                                    ) +
                                                    "】" +
                                                    _vm._s(item.title) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-size": "13px",
                                                  "font-family": "PingFang SC",
                                                  "font-weight": "500",
                                                  color: "#999999",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(item.updatePerson) +
                                                    " " +
                                                    _vm._s(item.createTime) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "nrbl_bottom" }, [
                _c(
                  "div",
                  { staticClass: "nrbl_nav" },
                  _vm._l(_vm.allTabList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          "nn_item",
                          _vm.tabIndex == index ? "active" : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.changeTab(index, item)
                          },
                        },
                      },
                      [
                        _vm._v(_vm._s(item.tabName) + " "),
                        _vm.totalCount[index] != 0
                          ? _c("div", { staticClass: "flag" }, [
                              _vm._v(_vm._s(_vm.totalCount[index])),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
                _vm.messageList
                  ? _c(
                      "div",
                      { staticClass: "nrbl_box" },
                      _vm._l(_vm.messageList, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "nrblb_item",
                            on: {
                              click: function ($event) {
                                return _vm.goOtherSys(
                                  item.buttonStatus,
                                  index,
                                  item
                                )
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "ni_img",
                              attrs: {
                                src: "https://oss.71go.com/noa0906/noa22.png",
                                alt: "",
                              },
                            }),
                            _c("div", { staticClass: "examine" }, [
                              _c("div", [
                                _vm._v(_vm._s(item.title) + " "),
                                _c("span", { staticClass: "normal" }, [
                                  _vm._v("正常"),
                                ]),
                                item.type == 1
                                  ? _c("span", { staticClass: "normalIcon" })
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "tips" }, [
                                item.realName
                                  ? _c("span", [
                                      _vm._v(
                                        "提报人：" + _vm._s(item.realName)
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(
                                    " 提报时间：" + _vm._s(item.createdTime)
                                  ),
                                ]),
                              ]),
                            ]),
                            _c("div", { staticClass: "approval" }, [
                              _vm._v(" 查看详情 >"),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c("img", {
                      staticClass: "no_data",
                      attrs: {
                        src: require("../../assets/oa/wp/nodata.png"),
                        alt: "",
                      },
                    }),
              ]),
            ]),
            _c("div", { staticClass: "nrb_right" }, [
              _c("div", { staticClass: "nrbr_top" }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.rqFlag,
                        expression: "rqFlag",
                      },
                    ],
                    staticClass: "nbr_calendar",
                  },
                  [
                    _c("img", {
                      staticClass: "add_icon",
                      attrs: {
                        src: "https://oss.71go.com/noa0906/noa19.png",
                        alt: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addItem()
                        },
                      },
                    }),
                    _c("div", { staticClass: "calendar_top" }, [
                      _c("div", { staticClass: "ct_years" }, [
                        _c("span", { staticClass: "year_time" }, [
                          _vm._v(
                            _vm._s(_vm.year) + "年 " + _vm._s(_vm.month) + "月"
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "ct_years" }, [
                        _c("img", {
                          staticClass: "left_triangle",
                          attrs: {
                            src: "https://oss.71go.com/noa0906/noa34.png",
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeMonth(0)
                            },
                          },
                        }),
                        _c("img", {
                          staticClass: "right_triangle",
                          attrs: {
                            src: "https://oss.71go.com/noa0906/noa34.png",
                            alt: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.changeMonth(1)
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        [
                          _c(
                            "el-dropdown",
                            {
                              attrs: { placement: "bottom" },
                              on: { command: _vm.isShowLInk },
                            },
                            [
                              _c("img", {
                                staticStyle: { cursor: "pointer" },
                                attrs: {
                                  src: "https://oss.71go.com/20231031bg/rc2.png",
                                  alt: "",
                                },
                              }),
                              _c(
                                "el-dropdown-menu",
                                {
                                  attrs: { slot: "dropdown" },
                                  slot: "dropdown",
                                },
                                [
                                  _vm.isEditLink
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.editLink()
                                            },
                                          },
                                        },
                                        [_vm._v("编辑链接")]
                                      )
                                    : _vm._e(),
                                  !!_vm.openLink
                                    ? _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.openSchedule()
                                            },
                                          },
                                        },
                                        [_vm._v("本月日程")]
                                      )
                                    : _c(
                                        "el-dropdown-item",
                                        {
                                          nativeOn: {
                                            click: function ($event) {
                                              return _vm.openSchedule()
                                            },
                                          },
                                        },
                                        [_vm._v("暂无日程")]
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "calendar_center" },
                      [
                        _c(
                          "el-carousel",
                          {
                            ref: "carousel",
                            attrs: {
                              interval: 0,
                              "indicator-position": "none",
                              autoplay: false,
                              loop: false,
                              height: "256px",
                              arrow: "always",
                            },
                            on: { setActiveItem: _vm.changeActiveItem },
                          },
                          [
                            _c("el-carousel-item", [
                              _c("div", { staticClass: "cct_list" }, [
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("一"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("二"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("三"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("四"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("五"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("六"),
                                ]),
                                _c("div", { staticClass: "cct_item" }, [
                                  _vm._v("日"),
                                ]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "ccb_list" },
                                _vm._l(_vm.dayArr, function (day, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      class: [
                                        "ccb_item",
                                        !day.belong ? " no_belong" : "",
                                        "" +
                                          _vm.year +
                                          (9 >= _vm.month
                                            ? "0" + _vm.month
                                            : _vm.month) +
                                          (9 >= _vm.timeToday
                                            ? "0" + _vm.timeToday
                                            : _vm.timeToday) ==
                                          day.day && day.belong
                                          ? "active"
                                          : "",
                                      ],
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeDateItem(day)
                                        },
                                      },
                                    },
                                    [
                                      day.belong
                                        ? _c("div", { staticClass: "new_c" }, [
                                            _vm._v(_vm._s(day.key)),
                                          ])
                                        : _vm._e(),
                                      day.list &&
                                      day.list.length &&
                                      day.belong > 0
                                        ? _c("div", { staticClass: "dot" })
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "calendar_bottom" },
                      [
                        _vm.activities && _vm.activities.length > 0
                          ? _c(
                              "el-timeline",
                              { attrs: { reverse: true } },
                              _vm._l(
                                _vm.activities,
                                function (activity, index) {
                                  return _c(
                                    "el-timeline-item",
                                    {
                                      key: index,
                                      attrs: {
                                        color: "#719cff",
                                        timestamp: activity.startDate,
                                        "hide-timestamp": true,
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "time_stamp" }, [
                                        _c(
                                          "span",
                                          { staticClass: "tle_leng" },
                                          [_vm._v(" " + _vm._s(activity.title))]
                                        ),
                                        activity.dateType == 1
                                          ? _c(
                                              "span",
                                              { staticClass: "be_done" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "shrinkFont" },
                                                  [_vm._v("待办")]
                                                ),
                                              ]
                                            )
                                          : activity.dateType == 2
                                          ? _c(
                                              "span",
                                              { staticClass: "vacation" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "shrinkFont" },
                                                  [_vm._v("假期")]
                                                ),
                                              ]
                                            )
                                          : _c(
                                              "span",
                                              { staticClass: "other" },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "shrinkFont" },
                                                  [_vm._v("其他事项")]
                                                ),
                                              ]
                                            ),
                                        activity.dateType == 1
                                          ? _c("img", {
                                              staticClass: "close",
                                              attrs: {
                                                src: require("../../assets/oa/wp/close.png"),
                                                alt: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delActivity(
                                                    activity.id
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              1
                            )
                          : _c("div", { staticClass: "no_msg" }, [
                              _vm._v("暂无事项"),
                            ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "nrbr_bottom" }, [
                _c("div", { staticClass: "fast_entrance" }, [
                  _vm._m(3),
                  _c(
                    "div",
                    { staticClass: "fast_entrance_box" },
                    _vm._l(_vm.fastList, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "fe_item",
                          on: {
                            click: function ($event) {
                              return _vm.goOtherSys(
                                item.buttonStatus,
                                index,
                                item,
                                2
                              )
                            },
                          },
                        },
                        [
                          _c("img", { attrs: { src: item.icon, alt: "" } }),
                          _c("div", [_vm._v(_vm._s(item.tabName))]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          staticStyle: { "margin-top": "300px" },
          attrs: {
            title: "添加我的待办",
            visible: _vm.dialogVisible,
            width: "500px",
            "before-close": _vm.handleClose,
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "80px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "日期" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "value-format": "timestamp",
                      format: "yyyyMMdd",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.form.dateStr,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "dateStr", $$v)
                      },
                      expression: "form.dateStr",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "我的待办" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addCalendar } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisiblePre,
            width: "1000px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisiblePre = $event
            },
          },
        },
        [
          _vm.htmlCon
            ? _c("div", { staticClass: "content-box" }, [
                _c("div", { staticClass: "head-title" }, [
                  _vm._v(_vm._s(_vm.businessJobName)),
                ]),
                _vm.htmlCon
                  ? _c("div", {
                      staticClass: "content",
                      domProps: { innerHTML: _vm._s(_vm.htmlCon) },
                      on: { click: _vm.imageEnlargement },
                    })
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.openChat,
            width: "1200px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openChat = $event
            },
          },
        },
        [
          _c("div", { staticClass: "openChat" }, [
            _c("iframe", {
              attrs: {
                frameborder: "0",
                src: "https://nxx.simengqifu.com/#?token=" + _vm.getToken(),
              },
            }),
          ]),
        ]
      ),
      _vm.allPreviewImg
        ? _c("el-image-viewer", {
            attrs: {
              "on-close": _vm.closeViewer,
              "url-list": _vm.srcList,
              "z-index": 9999,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nobl_tle" }, [
      _c("img", {
        staticClass: "nobll_img",
        attrs: { src: "https://oss.71go.com/noa0906/noa17.png", alt: "" },
      }),
      _c("div", { staticClass: "nobll_msg" }, [
        _vm._v(" 司盟企服 智能办公平台 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fast_entrance_title" }, [
      _c("div", { staticClass: "fast_entrance_title_item" }, [
        _vm._v("快捷入口"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nrbrb_tle" }, [
      _c("div", [_vm._v("集团通知")]),
      _c("div"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fast_entrance_title" }, [
      _c("div", { staticClass: "fast_entrance_title_item" }, [
        _vm._v("系统入口"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }