var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticStyle: { "min-width": "1100px" }, attrs: { gutter: 10 } },
    [
      _c(
        "el-col",
        { staticStyle: { padding: "0 15px" }, attrs: { span: 24 } },
        [
          _c(
            "div",
            { staticClass: "person_rank" },
            [
              _c("productHeard", {
                staticStyle: {},
                attrs: { productOptions: _vm.productOptions },
                on: { productChange: _vm.productChange },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }