var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login",
      style: {
        display: _vm.isBackgroundLoaded ? "flex" : "none",
        backgroundImage: _vm.isBackgroundLoaded
          ? "url(" + _vm.backgroundImageUrl + ")"
          : "none",
        margin: "0 auto",
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c("h3", { staticClass: "title" }, [
            _c("div", { staticClass: "titleLeft" }, [
              _c("img", {
                attrs: {
                  src: "https://oss.71go.com/qyg-pc/logoIcon.png",
                  width: "110px",
                  height: "57px",
                  alt: "",
                },
              }),
              _vm._v(" 司盟企服 "),
            ]),
            _vm._v(" 智能办公平台 "),
          ]),
          _c("div", { staticClass: "titleTxt" }, [_vm._v("欢迎登录")]),
          !this.scanFlag
            ? _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: "text",
                        "auto-complete": "off",
                        placeholder: "账号",
                      },
                      model: {
                        value: _vm.loginForm.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "username", $$v)
                        },
                        expression: "loginForm.username",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon_pos",
                        attrs: {
                          slot: "prefix",
                          src: !_vm.loginForm.username
                            ? "https://oss.71go.com/qyg-pc/userName.png"
                            : "https://oss.71go.com/qyg-pc/userNames.png",
                        },
                        slot: "prefix",
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          !this.scanFlag
            ? _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        type: _vm.pwflag ? "text" : "password",
                        "auto-complete": "off",
                        placeholder: "密码",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "icon_pos",
                        attrs: {
                          slot: "prefix",
                          src: !_vm.loginForm.password
                            ? "https://oss.71go.com/qyg-pc/passwordIcon.png"
                            : "https://oss.71go.com/qyg-pc/passwordIcons.png",
                        },
                        slot: "prefix",
                      }),
                      !_vm.pwflag
                        ? _c("img", {
                            staticClass: "icon_pos",
                            attrs: {
                              slot: "suffix",
                              src: require("../../assets/oa/eye.png"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.pwflag = !_vm.pwflag
                              },
                            },
                            slot: "suffix",
                          })
                        : _c("img", {
                            staticClass: "icon_pos",
                            attrs: {
                              slot: "suffix",
                              src: require("../../assets/oa/show.png"),
                            },
                            on: {
                              click: function ($event) {
                                _vm.pwflag = !_vm.pwflag
                              },
                            },
                            slot: "suffix",
                          }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: this.scanFlag,
                expression: "this.scanFlag",
              },
            ],
            staticClass: "scanCode",
            attrs: { id: "qr_login" },
          }),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              !_vm.scanFlag &&
              (!_vm.loginForm.username || !_vm.loginForm.password)
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        width: "379px",
                        height: "50px",
                        background: "#6C7EB2",
                        "border-radius": "25px",
                        "margin-top": "20px",
                        "font-size": "20px",
                        "font-family": "PingFang SC",
                        color: "#FFFFFF",
                      },
                      attrs: {
                        loading: _vm.loading,
                        size: "medium",
                        type: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [
                      !_vm.loading
                        ? _c("span", [_vm._v("登 录")])
                        : _c("span", [_vm._v("登 录 中...")]),
                    ]
                  )
                : _vm._e(),
              !_vm.scanFlag && _vm.loginForm.username && _vm.loginForm.password
                ? _c(
                    "el-button",
                    {
                      staticStyle: {
                        width: "379px",
                        height: "50px",
                        background:
                          "linear-gradient(90deg, #5D61FF 19%, #4981F3 87%)",
                        "border-radius": "25px",
                        "margin-top": "20px",
                        "font-size": "20px",
                        "font-family": "PingFang SC",
                        color: "#FFFFFF",
                      },
                      attrs: {
                        loading: _vm.loading,
                        size: "medium",
                        type: "primary",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [
                      !_vm.loading
                        ? _c("span", [_vm._v("登 录")])
                        : _c("span", [_vm._v("登 录 中...")]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-form-item", { staticStyle: { width: "100%" } }, [
            _c(
              "div",
              {
                staticClass: "other_mothods",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.scanLogin($event)
                  },
                },
              },
              [_c("span", [_vm._v("切换登录方式>>")])]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }