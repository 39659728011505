<template>
  <div style="box-sizing:border-box;padding:15px">
    <div class="nav">
      <div @click="changeNav(item)" v-for=" item in navList" :class="['nav_item',navActive == item.id? 'active':'']" :key="item.id">{{item.name}}</div>

    </div>
    <el-col v-if="navActive == 1" class="order_bg" :span="24">
      <el-form :inline="true" :model="queryData" ref="queryForm" class="demo-form-inline">

        <el-form-item label="签单时间" prop="payTime">
          <el-date-picker style="width:300px" @clear="payTimehandleClear" size="mini" v-model="queryData.payTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>

        <el-form-item label="订单编号" prop="orderCode">
          <el-input size="mini" v-model="queryData.orderCode" placeholder="请输入订单编号" clearable></el-input>
        </el-form-item>

        <el-form-item label="产品分类" prop="classifyNameTwo">
          <el-select v-model="queryData.classifyNameTwo" placeholder="请选择产品分类" clearable size="mini">
            <el-option v-for="item in productCategories" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="产品名称" prop="productName">
          <el-input size="mini" v-model="queryData.productName" placeholder="请输入产品名称" clearable></el-input>
        </el-form-item>

        <el-form-item label="客户来源" prop="customerSource">
          <el-select clearable style="width:100%" v-model="queryData.customerSource" placeholder="请选择客户来源">
            <el-option label="网络客户" value="网络客户"></el-option>
            <el-option label="转介绍" value="转介绍"></el-option>
            <el-option label="老客户" value="老客户"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单类型" prop="orderType">

          <el-select clearable style="width:100%" v-model="queryData.orderType" placeholder="请选择订单类型">
            <el-option label="续费订单" value="续费订单"></el-option>
            <el-option label="增值订单" value="增值订单"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="部门名称" prop="departmentName">
          <el-select clearable size="mini" v-model="queryData.departmentName" placeholder="请选择部门">
            <el-option v-for="item in allDepartment" :key="item.departmentId" :label="item.departmentName" :value="item.departmentName">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="业绩归属人" prop="pushCustomerName">
          <el-input size="mini" v-model="queryData.pushCustomerName" placeholder="请输入业绩归属人" clearable></el-input>
        </el-form-item>

        <!-- <el-form-item label="是否有效单" prop="isEffective">
          <el-select clearable size="mini" v-model="queryData.isEffective" placeholder="请选择是否有效单">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否合作单" prop="isCooperation">
          <el-select clearable size="mini" v-model="queryData.isCooperation" placeholder="请选择是否有效单">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>

<!--        <el-form-item label="是否当月退款" prop="isAcrossRefund">-->
<!--          <el-select clearable size="mini" v-model="queryData.isAcrossRefund" placeholder="请选择是否当月退款">-->
<!--            <el-option label="是" value="1"></el-option>-->
<!--            <el-option label="否" value="0"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="退单时间" prop="payTime">-->
<!--          <el-date-picker style="width:300px" @clear="refundTimehandleClear" size="mini" v-model="queryData.refundTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable-->
<!--            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>-->
<!--        </el-form-item>-->

        <el-form-item>
          <el-button size="mini" type="primary" @click="search">查询</el-button>
          <el-button size="mini" plain type="primary" @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <div v-if="(tableData&&tableData.length>0) && (totalData.num || totalData.price)" class="total_data">
        <el-tag  type="success" v-for="(item,index) in orderData" :key="index">
          {{item.orderType}}:{{item.orderCount}}
        </el-tag>
        <el-tag  v-for="(item,index) in statisticsData" :key="index">
          {{item.customerSource}}:{{item.orderCount}}
        </el-tag>
        <el-tag type="danger"> 产品数量：{{totalData.num}}</el-tag>
        <el-tag type="danger">合计金额：{{Number(totalData.price).toFixed(2) | numberWithCommas}}元</el-tag>
      </div>
      <el-table :data="tableData" ref="table" stripe border height="530" :header-cell-style="{ background: '#f2f3f5', color: '#7a7e83' }">
        <el-table-column type="index" align="center" width="50" label="序号">
        </el-table-column>
        <el-table-column align="center" min-width="200" prop="orderCode" label="订单号"></el-table-column>
        <el-table-column align="center" min-width="150" prop="customerNo" label="客户编码"></el-table-column>
        <el-table-column align="center" min-width="150" prop="departmentName" label="部门名称"></el-table-column>
        <el-table-column align="center" min-width="100" prop="pushCustomerName" label="业绩归属人"></el-table-column>
        <el-table-column align="center" min-width="200" prop="payTime" label="签单时间" width="180">
        </el-table-column>
        <el-table-column align="center" min-width="100" prop="payType" label="支付方式"></el-table-column>
        <el-table-column align="center" min-width="100" prop="productId" label="产品id"></el-table-column>
        <el-table-column align="center" min-width="330" prop="skuName" label="产品名称"></el-table-column>
        <el-table-column align="center" min-width="200" prop="classifyNameTwo" label="产品分类"></el-table-column>
        <el-table-column align="center" min-width="100" prop="skuNum" label="产品数量"></el-table-column>
        <el-table-column align="center" min-width="130" prop="price" label="实付总价"></el-table-column>
        <el-table-column align="center" min-width="100" prop="isCooperation" label="是否合作单">
          <template slot-scope="scope">
            {{scope.row.isCooperation == 1?"是":"否"}}
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="100" prop="cooperationType" label="合作模式">
          <template slot-scope="scope">
            <div v-show=" scope.row.isCooperation">
              {{scope.row.cooperationType == 1?"比例":"产品"}}
            </div>

          </template>
        </el-table-column>
        <el-table-column align="center" min-width="200" prop="masterName" label="公司名称"></el-table-column>

        <el-table-column align="center" min-width="200" prop="customerSource" label="客户来源"></el-table-column>
        <el-table-column align="center" min-width="200" prop="orderType" label="订单类型"></el-table-column>
        <el-table-column align="center" min-width="200" prop="serviceCycle" label="服务周期"></el-table-column>

        <el-table-column align="center" min-width="200" prop="refundTime" label="退款时间" width="180">
        </el-table-column>
        <el-table-column align="center" min-width="100" prop="isAcrossRefund" label="是否当月退款">
          <template slot-scope="scope">
            {{scope.row.isAcrossRefund == 1?"是":"否"}}
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" min-width="100" prop="isEffective" label="是否有效单">
          >
          <template slot-scope="scope">
            {{scope.row.isEffective == 1?"是":"否"}}
          </template>
        </el-table-column> -->
        <el-table-column align="center" min-width="200" prop="remark" label="备注"></el-table-column>
        <el-table-column fixed="right" min-width="150" label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">{{scope.row.additionalId?'修改内容':'补充内容'}}
            </el-button>

          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="queryData.pageNum" :limit.sync="queryData.pageSize" @pagination="getList" />

    </el-col>
    <el-col v-if="navActive == 2" class="order_bg" :span="24">

      <el-form :inline="true" :model="refundData" ref="refunForm" class="demo-form-inline">

        <el-form-item label="业绩归属人" prop="pushCustomerName">
          <el-input size="mini" v-model="refundData.pushCustomerName" placeholder="请输入业绩归属人" clearable></el-input>
        </el-form-item>

        <el-form-item label="订单编号" prop="orderCode">
          <el-input size="mini" v-model="refundData.orderCode" placeholder="请输入订单编号" clearable></el-input>
        </el-form-item>

        <el-form-item label="部门名称" prop="departmentName">
          <el-select clearable size="mini" v-model="refundData.departmentName" placeholder="请选择部门">
            <el-option v-for="item in allDepartment" :key="item.departmentId" :label="item.departmentName" :value="item.departmentName">
            </el-option>
          </el-select>

        </el-form-item>
        <el-form-item label="产品分类" prop="classifyNameTwo">

          <el-select v-model="refundData.classifyNameTwo" placeholder="请选择产品分类" clearable size="mini">
            <el-option v-for="item in productCategories" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="产品名称" prop="productName">
          <el-input size="mini" v-model="refundData.productName" placeholder="请输入产品名称" clearable></el-input>
        </el-form-item>

        <!-- <el-form-item label="退款发起人" prop="refundPersonName">
          <el-input size="mini" v-model="refundData.refundPersonName" placeholder="请输入退款发起人" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="退款原因" prop="refundcase">
          <el-input size="mini" v-model="refundData.refundCase" placeholder="请输入退款原因" clearable></el-input>
        </el-form-item>

        <el-form-item label="退款类型" prop="refundType">
          <el-select clearable size="mini" v-model="refundData.refundType" placeholder="请选择退款类型">
            <el-option label="客户预存款" value="客户预存款"></el-option>
            <el-option label="退款" value="退款"></el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="退款状态" prop="refundStatus">
          <el-select clearable size="mini" v-model="refundData.refundStatus" placeholder="请选择退款状态">
            <el-option label="已申请" value="1"></el-option>
            <el-option label="退款中" value="2"></el-option>
            <el-option label="退款成功" value="3"></el-option>
            <el-option label="退款失败" value="4"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="是否有效单" prop="isEffective">
          <el-select clearable size="mini" v-model="refundData.isEffective" placeholder="请选择是否有效单">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="是否当月退款" prop="isAcrossRefund">
          <el-select clearable size="mini" v-model="refundData.isAcrossRefund" placeholder="请选择是否当月退款">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退单时间" prop="payTime">
          <el-date-picker @clear="refundTimehandleClear" size="mini" v-model="refundData.refundTime" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable
            format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否合作单" prop="isCooperation">
          <el-select clearable size="mini" v-model="refundData.isCooperation" placeholder="请选择是否有效单">
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" type="primary" @click="refundSearch">查询</el-button>
          <el-button size="mini" plain type="primary" @click="refundResetForm">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="refunTable" ref="refunTable" stripe border height="580" :header-cell-style="{ background: '#f2f3f5', color: '#7a7e83' }">
        <el-table-column type="index" align="center" width="50" label="序号">
        </el-table-column>
        <el-table-column align="center" min-width="200" prop="orderCode" label="订单号"></el-table-column>
        <el-table-column align="center" min-width="150" prop="customerNo" label="客户编码"></el-table-column>
        <el-table-column align="center" min-width="100" prop="pushCustomerName" label="业绩归属人"></el-table-column>
        <el-table-column align="center" min-width="100" prop="departmentName" label="部门名称" width="180">
        </el-table-column>
        <el-table-column align="center" min-width="200" prop="classifyNameTwo" label="产品分类"></el-table-column>
        <el-table-column align="center" min-width="100" prop="productId" label="产品id"></el-table-column>
        <el-table-column align="center" min-width="330" prop="skuName" label="产品名称"></el-table-column>
        <el-table-column align="center" min-width="100" prop="skuNum" label="购买数量"></el-table-column>
        <el-table-column align="center" min-width="100" prop="refundNum" label="退款数量"></el-table-column>
        <el-table-column align="center" min-width="150" prop="refundPrice" label="退款金额"></el-table-column>
        <el-table-column align="center" min-width="150" prop="actuallyRefundPrice" label="退款实际金额"></el-table-column>

        <el-table-column align="center" min-width="200" prop="sheetId" label="退款流程号"></el-table-column>
        <el-table-column align="center" min-width="200" prop="refundCase" label="退单原因"></el-table-column>

        <el-table-column align="center" min-width="100" prop="refundStatus" label="退款状态">

        </el-table-column>

        <el-table-column align="center" min-width="100" prop="refundType" label="退款类型">
          <template slot-scope="scope">
            {{scope.row.refundType}}
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="160" prop="applyTime" label="发起日期"></el-table-column>
        <el-table-column align="center" min-width="160" prop="refundTime" label="退款时间"></el-table-column>

        <el-table-column align="center" min-width="120" prop="isAcrossRefund" label="是否当月退款">
          <template slot-scope="scope">
            {{scope.row.isAcrossRefund == 1?"是":"否"}}
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="100" prop="isCooperation" label="是否合作单">
          <template slot-scope="scope">
            {{scope.row.isCooperation == 1?"是":"否"}}
          </template>
        </el-table-column>
        <el-table-column align="center" min-width="100" prop="cooperationType" label="合作模式">
          <template slot-scope="scope">
            <div v-show=" scope.row.isCooperation">
              {{scope.row.cooperationType == 1?"比例":"产品"}}
            </div>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="200" prop="remark" label="备注"></el-table-column>

      </el-table>
      <pagination v-show="total>0" :total="refundTotal" :page.sync="refundData.pageNum" :limit.sync="refundData.pageSize" @pagination="getRefunTable" />
    </el-col>

    <el-dialog :title="newData.id ? '修改内容' : '补充内容'" :visible.sync="dialogVisible" :center="true" width="500px">
      <el-form :model="newData" label-width="120px">
        <el-form-item label="客户来源" prop="customerSource">
          <el-select style="width:100%" v-model="newData.customerSource" placeholder="请选择客户来源">
            <el-option label="网络客户" value="网络客户"></el-option>
            <el-option label="转介绍" value="转介绍"></el-option>
            <el-option label="老客户" value="老客户"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单类型" prop="orderType">
          <el-select style="width:100%" v-model="newData.orderType" placeholder="请选择订单类型">
            <el-option label="续费订单" value="续费订单"></el-option>
            <el-option label="增值订单" value="增值订单"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公司名称" prop="masterName">
          <el-input v-model="newData.masterName"></el-input>
        </el-form-item>
        <el-form-item label="服务周期" prop="serviceCycle">
          <el-date-picker style="width:100%" type="daterange" v-model="newData.serviceCycle" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" clearable
            format="yyyy-MM-dd"></el-date-picker>

        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="newData.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" @click="saveOrUpdate">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  findDepartmentAll,
  pageQuery,
  createOrder,
  updateOrder,
  pageQueryRefund,
  customerSourceStatistics,
  orderTypeStatistics,
  orderProductStatistics,
} from "@/api/analysis/data";
export default {
  filters: {},
  data() {
    return {
      productCategories: [
        "财税审计",
        "公司注册",
        "知识产权",
        "资质办理",
        "科技服务",
        "金融服务",
        "法律服务",
        "银行服务",
        "设计服务",
        "资产评估",
        "园区运营",
        "商务服务",
        "生态产品",
      ],
      dialogVisible: false,
      refundTotal: 0,
      navActive: 1,
      total: 0,
      refundData: {
        pageNum: 1,
        pageSize: 15,
        customerSource: "",
        orderType: "",
        departmentName: "",
        pushCustomerName: "",
        isEffective: "",
        isAcrossRefund: "",
        refundStartTime: "",
        refundEndTime: "",
        payTime: "",
        refundTime: "",
        isCooperation: "",
        refundCase: "",
        refundType: "",
        classifyNameTwo: "",
        orderCode:"",
        productName:""
      },
      refunTable: [],
      queryData: {
        pageNum: 1,
        pageSize: 15,
        startTime: "",
        endTime: "",
        customerSource: "",
        orderType: "",
        departmentName: "",
        pushCustomerName: "",
        isEffective: "",
        isAcrossRefund: "",
        refundStartTime: "",
        refundEndTime: "",
        payTime: "",
        refundTime: "",
        isCooperation: "",
        classifyNameTwo: "",
        orderCode:"",
        productName:""
      },
      tableData: [], // Data will be dynamically bound here
      navList: [
        { id: 1, name: "订单列表" },
        { id: 2, name: "退款列表" },
      ], //
      newData: {
        orderId: null,
        orderSkuId: null,
        customerSource: "",
        orderType: "",
        masterName: "",
        serviceCycle: "",
        id: null,
        remark: "",
      },
      allDepartment: [],
      totalData: { num: null, price: null },
      statisticsData: [],
      orderData: [],
    };
  },
  computed: {},
  created() {
    this.getList();
    this.getfindDepartmentAll();

    // Call your API function here or set your data as needed and assign it to tableData
  },
  methods: {
    refundStatus(value) {
      switch (value) {
        case 1:
          return "未退款";
        case 2:
          return "退款中";
        case 3:
          return "退款成功";
        case 4:
          return "退款失败";
        default:
          return "未知状态";
      }
    },
    handleCancel() {
      this.dialogVisible = false;
      this.newData = {
        orderId: null,
        orderSkuId: null,
        customerSource: "",
        orderType: "",
        masterName: "",
        serviceCycle: "",
        additionalId: null,
      };
    },
    async saveOrUpdate() {
      if (this.newData.serviceCycle.length > 1) {
        this.newData.serviceCycle = this.newData.serviceCycle.join("~");
      }
      let res;
      if (this.newData.id) {
        res = await updateOrder(this.newData);
      } else {
        res = await createOrder(this.newData);
      }

      if (res.code == 200) {
        this.$modal.msgSuccess(res.msg);
        this.getList();
      } else {
        this.$modal.msgError(res.msg);
      }
      this.dialogVisible = false;
    },
    handleUpdate(row) {
      this.newData = {
        id: row.additionalId || null,
        orderId: row.orderId,
        orderSkuId: row.orderSkuId,
        customerSource: row.customerSource || "",
        orderType: row.orderType || "",
        masterName: row.masterName || "",
        remark: row.remark || "",
        serviceCycle: row.serviceCycle ? row.serviceCycle.split("~") : "",
      };
      this.dialogVisible = true;
    },

    changeNav(item) {
      this.navActive = item.id;
      if (this.navActive == 1) {
        this.getList();
      } else {
        this.getRefunTable();
      }
    },
    payTimehandleClear() {
      this.queryData.payTime =
        this.queryData.startTime =
        this.queryData.endTime =
          null;
      console.log(this.queryData.startTime);
    },
    refundTimehandleClear() {
      if (this.navActive == 1) {
        this.queryData.refundTime = this.queryData.refundStartTime = null;
        this.queryData.refundEndTime = null;
      } else {
        this.refundData.refundTime = this.refundData.refundStartTime = null;
        this.refundData.refundEndTime = null;
      }
    },

    refundSearch() {
      if (this.refundData.refundTime) {
        this.refundData.refundStartTime = this.refundData.refundTime[0];
        this.refundData.refundEndTime = this.refundData.refundTime[1];
      } else {
        this.refundData.refundStartTime = this.refundData.refundEndTime = null;
      }
      this.refundData.pageNum = 1;
      this.getRefunTable();
    },
    refundResetForm() {
      this.refundData.refundTime =
        this.refundData.refundStartTime =
        this.refundData.refundEndTime =
          null;
      // 重置表单
      this.$refs.refunForm.resetFields();
      this.getRefunTable();
    },

    search() {
      this.queryData.pageNum = 1;
      if (this.queryData.payTime) {
        this.queryData.startTime = this.queryData.payTime[0];
        this.queryData.endTime = this.queryData.payTime[1];
      } else {
        this.queryData.startTime = this.queryData.endTime = null;
      }
      if (this.queryData.refundTime) {
        this.queryData.refundStartTime = this.queryData.refundTime[0];
        this.queryData.refundEndTime = this.queryData.refundTime[1];
      } else {
        this.refundData.refundStartTime = this.refundData.refundEndTime = null;
      }
      this.getList();
    },
    resetForm() {
      // 重置表单
      this.$refs.queryForm.resetFields();
      this.queryData.payTime =
        this.queryData.startTime =
        this.queryData.endTime =
        this.queryData.refundTime =
        this.queryData.refundStartTime =
        this.queryData.refundEndTime =
          null;
      this.getList();
    },
    async getfindDepartmentAll() {
      let res = await findDepartmentAll();
      if (res.code == 200) {
        this.allDepartment = res.data;
      }
    },
    async getList() {
      let res = await pageQuery(this.queryData);
      this.tableData = res.rows;

      this.total = res.total;
      this.sourceStatistics();
      this.handleResize();
    },
    async getRefunTable() {
      let res = await pageQueryRefund(this.refundData);
      this.refunTable = res.rows;
      this.refundTotal = res.total;
    },
    async sourceStatistics() {
      const [res, res2, res3] = await Promise.all([
        customerSourceStatistics(this.queryData),
        orderTypeStatistics(this.queryData),
        orderProductStatistics(this.queryData),
      ]);
      if (res.code == 200) {
        this.statisticsData = res.data;
      }
      if (res2.code == 200) {
        this.orderData = res2.data;
      }
      if (res3.code == 200) {
        this.totalData.num = res3.data.productNumber;
        this.totalData.price = res3.data.productPrice;
      }
    },
    handleResize() {
      let that = this;
      that.$nextTick(() => {
        if (
          that.$refs.table &&
          typeof that.$refs.table.doLayout === "function"
        ) {
          that.$refs.table.doLayout();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.total_data {
  margin-bottom: 10px;
  font-size: 15px;
  padding: 5px;
  .el-tag {
    margin-right: 5px;
  }
}
.el-table {
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    height: 3px; /*滚动条高度*/
    z-index: 999;
  }

  /*定义滑块 内阴影+圆角*/
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #2c9aff inset; /*滚动条的内阴影*/
    border-radius: 10px; /*滚动条的圆角*/
    background-color: #2c9aff; /*滚动条的背景颜色*/
    cursor: pointer;
    width: 3px;
  }
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 5px; /* 设置滚动条宽度 */
  }
  ::v-deep .el-table__row td:not(.is-hidden):last-child {
    border-left: 1px solid #ebeef5;
  }

  ::v-deep .el-table__header th:not(.is-hidden):last-child {
    border-left: 1px solid #ebeef5;
  }

  ::v-deep .el-table__header-wrapper tr th:nth-last-child(1) {
    border-right: none;
  }

  ::v-deep .el-table__body-wrapper tr td:nth-last-child(1) {
    border-right: none;
  }
}
.order_bg {
  padding: 15px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 10px;
  position: relative;
}
.nav {
  display: flex;
  align-items: center;
  margin: 0px 0 15px;
  .nav_item {
    padding: 5px 25px;
    background: #fff;
    margin-right: 20px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
  }
  .active {
    background: linear-gradient(to right, #f1975c, #fc6c29);
    color: #fff;
  }
}
</style>

<style scoped>
::v-deep .el-table__fixed-right {
  right: 0px !important;
  height: 100%;
}
::v-deep .el-table__fixed-right .el-table__fixed-body-wrapper {
  height: auto !important;
  bottom: 4px !important;
  max-height: 100% !important;
}
::v-deep .el-table__fixed-right-patch {
  width: 5px !important;
}
::v-deep .el-table__fixed {
  height: auto !important;
  bottom: 0px !important;
}
::v-deep .el-table__fixed .el-table__fixed-body-wrapper {
  height: auto !important;
  bottom: 0px !important;
  max-height: 100% !important;
}
::v-deep .el-table--scrollable-x .el-table__fixed {
  height: auto !important;
  bottom: 0px !important;
}
::v-deep .el-table--scrollable-x .el-table__fixed-right {
  bottom: 0px !important;
}
::v-deep .el-table--scrollable-y .el-table__fixed-right {
  right: 5px !important;
  height: auto !important;
}
::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
  height: 7px !important;
}
::v-deep .el-table--fluid-height .el-table__fixed {
  bottom: 5px !important;
}
</style>
