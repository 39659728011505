<template>
  <div class="app-container">
  </div>
</template>

<script>

export default {
  name: "fileLogin",
  data() {
    return {
      loginForm: {
        redirect: null,
        userName: null,
        userId: null,
        nickName: null,
        token: null,
      },
      loading: null
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.loginForm = route.query;
      },
      immediate: true,
    }
  },
  created() {
    // 加载......
    this.loading = this.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(255, 255, 255, 0.7)",
    });
  },
  mounted() {
    this.login();
  },
  methods: {
    login() {
      console.log(this.loginForm)
      this.$store
        .dispatch("BpmLogin", this.loginForm)
        .then(() => {
          this.$router.push({path: this.loginForm.redirect || '/'}).catch(() => {
          });
          this.loading.close()
        })
    },
  },
};
</script>
<style>
.login {
  width: 100%;
  min-height: 850px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background: url("../../assets/oa/oabg.png") center no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;
}
</style>
