<template>
  <div class="login"
    :style="{ display: isBackgroundLoaded ? 'flex' : 'none', backgroundImage: isBackgroundLoaded ? `url(${backgroundImageUrl})` : 'none', margin: '0 auto', }">

    <!-- <img class="jt_logo" src="../../assets/oa/logo.png" alt=""> -->
    <!-- <div class="sys_msg">OA后台管理系统</div> -->


    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">
        <div class="titleLeft">
          <img src="https://oss.71go.com/qyg-pc/logoIcon.png" width="110px" height="57px" alt="">
          司盟企服
        </div>
        智能办公平台
      </h3>
      <div class="titleTxt">欢迎登录</div>
      <el-form-item prop="username" v-if="!this.scanFlag">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
          <img slot="prefix" class="icon_pos"
            :src="!loginForm.username ? 'https://oss.71go.com/qyg-pc/userName.png' : 'https://oss.71go.com/qyg-pc/userNames.png'" />
        </el-input>
      </el-form-item>
      <el-form-item prop="password" v-if="!this.scanFlag">
        <el-input v-model="loginForm.password" :type="pwflag ? 'text' : 'password'" auto-complete="off" placeholder="密码"
          @keyup.enter.native="handleLogin">
          <img slot="prefix" class="icon_pos"
            :src="!loginForm.password ? 'https://oss.71go.com/qyg-pc/passwordIcon.png' : 'https://oss.71go.com/qyg-pc/passwordIcons.png'" />
          <img slot="suffix" v-if="!pwflag" @click="pwflag = !pwflag" class="icon_pos" src="../../assets/oa/eye.png" />
          <img slot="suffix" v-else @click="pwflag = !pwflag" class="icon_pos" src="../../assets/oa/show.png" />
        </el-input>
      </el-form-item>
      <div class="scanCode" id="qr_login" v-show="this.scanFlag"></div>
      <!-- <el-checkbox v-model="loginForm.rememberMe" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button v-if="!scanFlag && (!loginForm.username || !loginForm.password)" :loading="loading" size="medium"
          type="primary"
          style="width: 379px;height: 50px;background: #6C7EB2;border-radius: 25px;margin-top:20px;font-size: 20px;font-family: PingFang SC;color: #FFFFFF;"
          @click.native.prevent="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>
        <el-button v-if="!scanFlag && (loginForm.username && loginForm.password)" :loading="loading" size="medium"
          type="primary"
          style="width: 379px;height: 50px;background: linear-gradient(90deg, #5D61FF 19%, #4981F3 87%);border-radius: 25px;margin-top:20px;font-size: 20px;font-family: PingFang SC;color: #FFFFFF;"
          @click.native.prevent="handleLogin">
          <span v-if="!loading">登 录</span>
          <span v-else>登 录 中...</span>
        </el-button>

      </el-form-item>
      <el-form-item style="width:100%;">
        <div class="other_mothods" @click.stop="scanLogin">
          <span>切换登录方式>></span>
        </div>
      </el-form-item>

    </el-form>

  </div>
</template>

<script>
import { thirdPartyLogin } from "@/api/analysis/oa";
import Cookies from "js-cookie";
import { decrypt, encrypt } from "@/utils/jsencrypt";

export default {
  name: "Login",
  data() {
    return {
      backgroundImageUrl: 'https://oss.71go.com/qyg-pc/logoBgc.png',
      isBackgroundLoaded: false,
      scanFlag: false,
      pwflag: false,
      codeUrl: "",
      loginForm: {
        username: "admin",
        password: "admin123",
        rememberMe: false,
        code: "",
        uuid: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "请输入您的账号" },
        ],
        password: [
          { required: true, trigger: "blur", message: "请输入您的密码" },
        ],
      },
      loading: false,
      // 验证码开关
      captchaOnOff: true,
      // 注册开关
      register: false,
      redirect: undefined,
      tagEle: "",
      paper: "",
      RADIUS: 250,
      fallLength: 1200,
      tags: [],
      angleX: Math.PI / 500,
      angleY: Math.PI / 500,
      CX: "",
      CY: "",
      EX: "",
      EY: "",
      timing: null,

    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  created() {
    document.title = "oa登录页";
    if (this.$route.query.token) {
      this.herfLogin(this.$route.query.token, this.$route.query.expire);
    }
    if (this.$route.query.code) {
      this.thirdPartyLogin(
        this.$route.query.code,
        this.$route.query.appid,
        this.$route.query.state
      );
    }

    this.getCookie();
  },
  mounted() {
    this.scanLogin();
    if (this.$refs.tagBall) {
      this.tagEle = this.$refs.tagBall.children;
      this.paper = this.$refs.tagBall;
      this.CX = this.paper.offsetWidth / 2;
      this.CY = this.paper.offsetHeight / 2;
      this.EX =
        this.paper.offsetLeft +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      this.EY =
        this.paper.offsetTop +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }


    this.innit();
    this.animate();
    const backgroundImage = new Image();
    backgroundImage.src = 'https://oss.71go.com/qyg-pc/logoBgc.png';

    backgroundImage.onload = () => {
      this.isBackgroundLoaded = true;
      console.log('this.isBackgroundLoaded', this.isBackgroundLoaded);
    };
  },

  methods: {
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberMe = Cookies.get("rememberMe");
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password:
          password === undefined ? this.loginForm.password : decrypt(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.loginForm.rememberMe) {
            Cookies.set("username", this.loginForm.username, { expires: 30 });
            Cookies.set("password", encrypt(this.loginForm.password), {
              expires: 30,
            });
            Cookies.set("rememberMe", this.loginForm.rememberMe, {
              expires: 30,
            });
          } else {
            Cookies.remove("username");
            Cookies.remove("password");
            Cookies.remove("rememberMe");
          }
          // console.log(this.loginForm);
          this.$store
            .dispatch("oaLogin", this.loginForm)
            .then(() => {
              this.$router.push({ path: "/oa/index" }).catch(() => { });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
    innit() {
      for (var i = 0; i < this.tagEle.length; i++) {
        var a, b;
        var k = (2 * (i + 1) - 1.2) / this.tagEle.length - 1;
        var a = Math.acos(k);
        var b = a * Math.sqrt(this.tagEle.length * Math.PI);
        var x = this.RADIUS * Math.sin(a) * Math.cos(b);
        var y = this.RADIUS * Math.sin(a) * Math.sin(b);
        var z = this.RADIUS * Math.cos(a);
        // var t = this.tag({ ele: this.tagEle[i], x, y, z });
        this.tagEle[i].style.color = "#4f7bfe";

        this.tags.push({ ele: this.tagEle[i], x, y, z });
      }
    },
    tag({ ele, x, y, z }) {
      var scale = this.fallLength / (this.fallLength - z);
      var alpha = (z + this.RADIUS) / (2 * this.RADIUS);
      ele.style.fontSize = 13 * scale + "px";
      ele.style.opacity = alpha;
      ele.style.filter = "alpha(opacity = " + alpha * 100 + ")";
      ele.style.zIndex = parseInt(scale * 200);
      ele.style.left = x + this.CX - ele.offsetWidth / 2 + "px";
      ele.style.top = y + this.CY - ele.offsetHeight / 1 + "px";
    },
    animate() {
      this.timing = setInterval(() => {
        this.rotateX();
        this.rotateY();
        this.tags.forEach((item) => {
          this.tag(item);
        });
      }, 30);
    },
    rotateX() {
      var cos = Math.cos(this.angleX);
      var sin = Math.sin(this.angleX);
      this.tags.forEach((item) => {
        var y1 = item.y * cos - item.z * sin;
        var z1 = item.z * cos + item.y * sin;
        item.y = y1;
        item.z = z1;
      });
    },
    rotateY() {
      var cos = Math.cos(this.angleY);
      var sin = Math.sin(this.angleY);
      this.tags.forEach((item) => {
        var x1 = item.x * cos - item.z * sin;
        var z1 = item.z * cos + item.x * sin;
        item.x = x1;
        item.z = z1;
      });
    },
    mouseOver(event) {
      var x = event.clientX - this.EX - this.CX;
      var y = event.clientY - this.EY - this.CY;
      console.log(x, y);
      this.angleY = x * 0.0001;
      this.angleX = y * 0.0001;
      if (!this.timing) this.animate();
    },
    mouseLeave(event) {
      var x = event.clientX - this.EX - this.CX;
      var y = event.clientY - this.EY - this.CY;
      this.angleY = x * 0.0001;
      this.angleX = y * 0.0001;
    },
    cheMouseOver() {
      clearInterval(this.timing);
      this.timing = null;
    },
    scanLogin() {
      this.scanFlag = !this.scanFlag;
      window.getQRCode({
        id: "qr_login",
        appid: "wwf95468b811076c2e",
        agentid: "1000131",
        redirect_uri: encodeURI(window.location.protocol + '//' + window.location.hostname + '/oa/login'),
        state: "hellowecom",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAyMDBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30KLmltcG93ZXJCb3h7d2lkdGg6IDIwMHB4O30KLmltcG93ZXJCb3ggLm5vcm1hbFBhbmVsIC53YWl0aW5nIC53cnBfY29kZV9pZnJhbWUge21hcmdpbjogMDt9Ci5pbXBvd2VyQm94IC5ub3JtYWxQYW5lbCAud2FpdGluZyAud3JwX2NvZGV7bWFyZ2luOiAwO30",
        lang: "zh",
      });
    },
    herfLogin(token, expire) {
      this.$store
        .dispatch("herfLogin", { token: token, expire: expire })
        .then(() => {
          this.$router.push({ path: "/oa/index" }).catch(() => { });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    thirdPartyLogin(code, appid, state) {
      thirdPartyLogin({ code: code, appid: appid, state: state }).then(
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.herfLogin(res.data.access_token, res.data.expires_in);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  background: url("https://oss.71go.com/qyg-pc/logoBgc.png") center no-repeat;
  background-size: 100% 100%;
  position: relative;
  overflow: hidden;



}

.title {
  color: #212121;
  margin: 40px 0 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 25px;
  font-family: PingFang SC;
  font-weight: 600;
  white-space: nowrap;

  .titleLeft {
    display: flex;
    align-items: center;
  }
}

.login-form {
  background: #3b3b3b75;
  box-shadow: 0px 0px 50px 0px rgba(97, 119, 180, 0.75);
  // opacity: 0.6;
  width: 460px;
  padding: 40px 36px 125px 36px;
  border-radius: 30px;
  max-height: 600px;
  position: relative;
  text-align: center;

  .titleTxt {
    margin-bottom: 47px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #FEFEFE;
  }

  .el-input {
    height: 54px;

    input {
      background: rgb(244 244 244 / 30%);
      border-radius: 10px;
      padding: 0px 55px;
      height: 54px;
    }

    .el-input__prefix {
      left: 20px;
      top: 15px;
    }

    .icon_pos {
      width: 24px;
      height: 24px;
    }

    .el-input__suffix {
      right: 20px;
      top: 15px;
      cursor: pointer;
    }
  }

  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}

.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}

.login-code {
  width: 33%;
  height: 38px;
  float: right;

  img {
    cursor: pointer;
    vertical-align: middle;
  }
}

.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}

.login-code-img {
  height: 38px;
}

.tagBall {
  max-width: 65%;
  // max-width: 950px;
  height: 400px;
  margin: 50px auto;
  position: relative;
  transform: scale(1.2);
  overflow: hidden;
}

.tag {
  display: block;
  user-select: none;
  position: absolute;
  left: 0px;
  top: 0px;
  color: #000;
  text-decoration: none;
  font-size: 15px;
  font-family: "微软雅黑";
  font-weight: bold;
  white-space: nowrap;
}

.scanCode {
  // width: 200px;
  // height: 200px;
  background-color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  display: contents;

  iframe {
    width: 200px;
    height: 200px;

    ::v-deep .impowerBox {
      margin: 0;
    }

    ::v-deep .wrp_code_iframe {
      margin: 0;
    }

    ::v-deep .wrp_code {
      margin: 0;
    }

  }
}

/* 通过选择器选择 iframe 内部元素并设置样式 */
 #qr_login iframe body .js_single_page_container .impowerBox {
  margin: 0;

}

#qr_login iframe body .js_single_page_container .impowerBox .normalPanel .waiting .wrp_code_iframe {
  margin-top: 0;
}

#qr_login iframe body .js_single_page_container .impowerBox .normalPanel .waiting .wrp_code {
  margin-top: 0;

}

/* 通过选择器选择 iframe 内部元素并设置样式 */
::v-deep #qr_login iframe body .js_single_page_container .impowerBox {
  margin: 0;

}

::v-deep #qr_login iframe body .js_single_page_container .impowerBox .normalPanel .waiting .wrp_code_iframe {
  margin-top: 0;
}

::v-deep #qr_login iframe body .js_single_page_container .impowerBox .normalPanel .waiting .wrp_code {
  margin-top: 0;

}
.other_mothods {
  width: 100%;
  text-align: center;
  height: 28px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #b1b1b1;
  line-height: 0px;
  cursor: pointer;
  margin-top: 30px;

}

.other_mothods:hover {
  color: #FFFFFF;
}</style>
